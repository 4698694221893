import { OTHER_PLEASE_SPECIFY } from './constants/stringVars.ts';
import { THealthDomain, THealthDomainScores, THealthSubDomain } from './types/Health.types.ts';
import { TPeerSupportEventOutcome } from './types/PeerSupport.types.ts';

export const BACKOFFICE_SIGN_IN_URL = 'https://backoffice.myomnia.health/sign-in';

export const EMPTY_WELLNESS_DOMAINS: THealthDomainScores = {
  mental: 0,
  physical: 0,
  social: 0,
  spiritual: 0,
  vocational: 0,
  purpose: 0,
  fun: 0,
};

export const READINESS_DOMAIN = 'readiness' as const;

export const WELLNESS_DOMAINS_LIST = [
  'mental',
  'physical',
  'social',
  'spiritual',
  'vocational',
  'purpose',
  'fun',
] as const;

export const DEFAULT_ITEMS_PER_PAGE = 10;
export const INFINITE_SCROLL_CARDS_PER_PAGE = 8;

export const WELLNESS_DOMAIN_QUESTION_TYPES = ['wellness_level', 'readiness_level'] as const;

export const NO_OF_WELLNESS_DOMAINS = Object.values(EMPTY_WELLNESS_DOMAINS).length;

export const WELLNESS_DATA_MULTIPLIER = 10;

export const WELLNESS_STATE_LIMIT_DANGER = 33;
export const WELLNESS_STATE_LIMIT_OK = 66;

export const HOUR_IN_MILISECONDS = 3600000;

export const MAX_LINES_IN_ROSTER = 50;

export const REFETCH_MILISECONDS = 1000;

export const MEMBER_NOTE_TYPES = ['PERSONAL', 'MEMBERCARE_TO_USER', 'USER_TO_MEMBERCARE'] as const;
export const TIMELINE_TYPES = ['assessment', 'screener', 'memberCareNote', 'selfNote', 'observation'] as const;

export const MIN_CHARS_IN_NOTE_CONTENT = 10;
export const MAX_CHARS_IN_NOTE_CONTENT = 1000;

export const MIN_CHARS_IN_PHONE = 8;
export const MAX_CHARS_IN_PHONE = 18;

export const MIN_CHARS_IN_BIO = 30;
export const MAX_CHARS_IN_BIO = 2000;

export const MIN_CHARS_TO_DO_SEARCH = 3;

export const ANSWER_VALUE_TO_TRIGGER_SUBDOMAINS = 7;

export const SUBDOMAINS_MENTAL = [
  'FEELING_SAD_OR_DOWN',
  'UNDERSTANDING_OTHERS_FEELINGS',
  'FEELING_NERVOUS_OR_WORRIED',
  'MANAGING_EMOTIONS',
  'DEALING_WITH_TRAUMA',
  'TROUBLE_DOING_DAILY_ACTIVITIES',
  'FEELING_OVERWHELMED',
  'SUICIDE',
  'OTHER_MENTAL',
] as const;

export const SUBDOMAINS_PHYSICAL = [
  'PHYSICAL_ACTIVITY',
  'HEALTH_CHECKUPS',
  'BODY_ACHES_OR_PAINS',
  'SLEEP_PATTERNS',
  'EATING_HABITS',
  'ALCOHOL_AND_OTHER_DRUG_USE',
  'COMPULSIVE_BEHAVIORS_EXCLUDING_ALCOHOL_AND_DRUGS',
  'OTHER_PHYSICAL',
] as const;

export const SUBDOMAINS_SOCIAL = [
  'FEELING_CLOSE_TO_PEOPLE',
  'RELATIONSHIP_WITH_PARENTS_AND_SIBLINGS',
  'FAMILY_TROUBLES_GROWING_UP',
  'WITHDRAWING_FROM_PEOPLE',
  'SUPPORT_FROM_FRIENDS_FAMILY_OR_COMMUNITY',
  'ANGER',
  'RELATIONSHIP_WITH_CHILDREN',
  'RELATIONSHIP_WITH_SPOUSE_OR_PARTNER',
  'COMMITMENT_TO_SPOUSE_OR_PARTNER',
  'OTHER_SOCIAL',
] as const;

export const SUBDOMAINS_SPIRITUAL = [
  'DISCONNECTED_FROM_SPIRITUALITY',
  'LACK_OF_SPIRITUAL_DEVOTION',
  'OTHER_SPIRITUAL',
] as const;

export const SUBDOMAINS_VOCATIONAL = [
  'RELATIONSHIP_WITH_EMPLOYER',
  'BALANCING_WORK_AND_PERSONAL_LIFE',
  'MANAGING_MONEY',
  'JOB_SATISFACTION',
  'OTHER_VOCATIONAL_FINANCIAL',
] as const;

export const SUBDOMAINS_PURPOSE = [
  'LIFE_SATISFACTION',
  'STUCK_OR_UNMOTIVATED',
  'DETERMINATION_AND_RESILIENCE',
  'OTHER_PURPOSE_RESILIENCE',
] as const;

export const SUBDOMAINS_FUN = [
  'LACK_OF_HOBBIES',
  'BORED_WITH_ROUTINES',
  'STRUGGLES_WITH_SELF_CARE',
  'NO_TIME_TO_RELAX',
  'OTHER_FUN_RELAXATION',
] as const;

export const WELLNESS_SUBDOMAINS_PER_DOMAIN: Record<THealthDomain, readonly string[]> = {
  mental: SUBDOMAINS_MENTAL,
  physical: SUBDOMAINS_PHYSICAL,
  social: SUBDOMAINS_SOCIAL,
  spiritual: SUBDOMAINS_SPIRITUAL,
  vocational: SUBDOMAINS_VOCATIONAL,
  purpose: SUBDOMAINS_PURPOSE,
  fun: SUBDOMAINS_FUN,
};

export const READINESS_SUBDOMAINS = [
  'NOT_IMPORTANT',
  'SCARED_OF_CHANGE',
  'MENTAL_HEALTH_CONCERNS',
  'DO_NOT_FEEL_SUPPORTED',
  'DO_NOT_KNOW_HOW',
  'DO_NOT_HAVE_TIME',
  'OTHER_READINESS',
];

export const WELLNESS_DOMAINS_DISPLAY_LABELS: Record<THealthDomain, string> = {
  physical: 'Physical',
  mental: 'Mental',
  social: 'Social',
  vocational: 'Vocational & Financial',
  spiritual: 'Spiritual',
  purpose: 'Purpose & Resilience',
  fun: 'Fun & Relaxation',
};

export const WELLNESS_DOMAINS_DISPLAY_LABELS_REVERSE: Record<string, THealthDomain> = Object.fromEntries(
  Object.entries(WELLNESS_DOMAINS_DISPLAY_LABELS).map((a) => a.reverse()),
);

export const WELLNESS_SUB_DOMAINS_DISPLAY_LABELS: Record<THealthSubDomain, string> = {
  FEELING_SAD_OR_DOWN: 'Feeling sad or down',
  FEELING_NERVOUS_OR_WORRIED: 'Feeling nervous or worried',
  TROUBLE_DOING_DAILY_ACTIVITIES: 'Trouble doing daily activities',
  FEELING_OVERWHELMED: 'Feeling overwhelmed',
  SUICIDE: 'Suicide',
  UNDERSTANDING_OTHERS_FEELINGS: "Understanding others' feelings",
  MANAGING_EMOTIONS: 'Managing emotions',
  DEALING_WITH_TRAUMA: 'Dealing with trauma',
  OTHER_MENTAL: 'Other Mental',

  PHYSICAL_ACTIVITY: 'Physical activity',
  BODY_ACHES_OR_PAINS: 'Body aches or pains',
  HEALTH_CHECKUPS: 'Health check-ups',
  SLEEP_PATTERNS: 'Sleep patterns',
  EATING_HABITS: 'Eating habits',
  ALCOHOL_AND_OTHER_DRUG_USE: 'Alcohol and other drug use',
  COMPULSIVE_BEHAVIORS_EXCLUDING_ALCOHOL_AND_DRUGS: 'Compulsive behaviors excluding alcohol and drugs',
  OTHER_PHYSICAL: 'Other Physical',

  FEELING_CLOSE_TO_PEOPLE: 'Feeling close to people',
  RELATIONSHIP_WITH_PARENTS_AND_SIBLINGS: 'Relationship with parents and siblings',
  RELATIONSHIP_WITH_SPOUSE_OR_PARTNER: 'Relationship with spouse or partner',
  RELATIONSHIP_WITH_CHILDREN: 'Relationship with children',
  COMMITMENT_TO_SPOUSE_OR_PARTNER: 'Commitment to spouse or partner',
  FAMILY_TROUBLES_GROWING_UP: 'Family troubles growing up',
  WITHDRAWING_FROM_PEOPLE: 'Withdrawing from people',
  SUPPORT_FROM_FRIENDS_FAMILY_OR_COMMUNITY: 'Support from friends, family or community',
  ANGER: 'Anger',
  OTHER_SOCIAL: 'Other Social',

  DISCONNECTED_FROM_SPIRITUALITY: 'Disconnected from spirituality',
  LACK_OF_SPIRITUAL_DEVOTION: 'Lack of spiritual devotion',
  OTHER_SPIRITUAL: 'Other Spiritual',

  BALANCING_WORK_AND_PERSONAL_LIFE: 'Balancing work and personal life',
  MANAGING_MONEY: 'Managing money',
  JOB_SATISFACTION: 'Job Satisfaction',
  RELATIONSHIP_WITH_EMPLOYER: 'Relationship with employer',
  OTHER_VOCATIONAL_FINANCIAL: 'Other Vocational or Financial',

  LIFE_SATISFACTION: 'Life satisfaction',
  DETERMINATION_AND_RESILIENCE: 'Determination and Resilience',
  STUCK_OR_UNMOTIVATED: 'Stuck or unmotivated',
  OTHER_PURPOSE_RESILIENCE: 'Other Purpose',

  LACK_OF_HOBBIES: 'Lack of hobbies',
  NO_TIME_TO_RELAX: 'No time to relax',
  STRUGGLES_WITH_SELF_CARE: 'Struggles with self-care',
  BORED_WITH_ROUTINES: 'Bored with routines',
  OTHER_FUN_RELAXATION: 'Other Fun or Relaxation',
};

export const READINESS_SUBDOMAINS_DISPLAY_LABELS: Record<string, string> = {
  NOT_IMPORTANT: 'It’s not important to me.',
  SCARED_OF_CHANGE: 'I ‘m scared of change.',
  MENTAL_HEALTH_CONCERNS: 'I’m dealing with mental health concerns. ',
  DO_NOT_FEEL_SUPPORTED: 'I don’t feel supported by others.',
  DO_NOT_KNOW_HOW: 'I don’t know how. ',
  DO_NOT_HAVE_TIME: 'I don’t have enough time.',
  // HAVE_NOT_THOUGHT:
  //   'I haven’t thought about it  /  I’m unsure if I really need to change  /  It’s not important to me. ',
  // DO_NOT_WANT: 'I don’t want to  /  I ‘m scared of change  /  I’m dealing with mental health concerns.',
  // TRIED_AND_FAILED: 'I’ve tried before and failed  /  I don’t feel supported by others.',
  // DO_NOT_KNOW_HOW: 'I don’t know how  /  I don’t have enough time.',
  OTHER_READINESS: OTHER_PLEASE_SPECIFY,
};

export const SCREENER_SUB_DOMAINS_DISPLAY_LABELS = {
  ...WELLNESS_SUB_DOMAINS_DISPLAY_LABELS,
  ...READINESS_SUBDOMAINS_DISPLAY_LABELS,
};

export const PEER_SUPPORT_EVENT_OUTCOME = [
  'NO_FURTHER_ACTION',
  'PEER_SUPPORT_FOLLOW_UP',
  'EMERGENCY_SUPPORT',
  'REFERRAL_TO_MEMBER_CARE',
  'REFERRAL_TO_THERAPIST',
] as const;

export const PEER_SUPPORT_EVENT_OUTCOME_DISPLAY_LABELS: Record<TPeerSupportEventOutcome, string> = {
  EMERGENCY_SUPPORT: 'Emergency Support',
  NO_FURTHER_ACTION: 'No further action',
  PEER_SUPPORT_FOLLOW_UP: 'Peer Support follow up',
  REFERRAL_TO_MEMBER_CARE: 'Referral to Member Care',
  REFERRAL_TO_THERAPIST: 'Referral to Therapist',
};

export const ORGANIZATION_EVENT_TYPE = [
  'MEMBER_CARE_OUTREACH',
  'PEER_SUPPORT_OUTREACH',
  'THERAPY_SESSION',
  'TRAININGS_AND_TOUCHPOINT',
  'PEER_SUPPORT_CONSULTATION',
] as const;

export const BOBBI_MC_GRAW_NAME = 'Bobbi McGraw';
export const ROB_JAMES_NAME = 'Rob James';
