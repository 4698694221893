import useOrganizationMemberCares from '@/hooks/membercare/useOrganizationMemberCares';
import MemberCareDirectoryCard from '@/modules/directory/cards/MemberCareDirectoryCard';
import DirectoryPageTemplate from '@/modules/directory/DirectoryPageTemplate';
import { createFileRoute } from '@tanstack/react-router';

const MemberCareDirectory = () => {
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useOrganizationMemberCares();

  return (
    <DirectoryPageTemplate
      title="Member Care Team"
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onViewMoreClick={() => fetchNextPage()}
    >
      {data?.map((memberCare) => <MemberCareDirectoryCard member={memberCare} key={memberCare?.user_id} />)}
    </DirectoryPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_member-care/directory/member-care')({
  component: MemberCareDirectory,
});
