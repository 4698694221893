import { useMutation, useQueryClient } from '@tanstack/react-query';
import useToast from '../useToast';
import { TCustomAxiosError } from '@/services/api';
import { useScreenerStore } from '@/store/useScreenerStore';
import { formatFormForBE } from '@/utils/screenersScores';
import { lastScoresGet, createAssessmentResultPost } from '@/services/api/requests/result';
import { useAppStore } from '@/store/useAppStore';
import { useNavigate } from '@tanstack/react-router';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import { THealthDomainScores } from '@/types/Health.types';
import { NO_OF_WELLNESS_DOMAINS } from '@/constants';

const useAssessmentResult = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { trackEvent } = useTelemetry();

  const { setUserScores } = useAppStore();
  const { screenerId, slug } = useScreenerStore();

  const { isPending, mutate, isSuccess } = useMutation({
    mutationFn: async ({ formValues }: { formValues: Record<string, any> }) => {
      const formattedFormForBE = formatFormForBE(formValues);
      const totalScore =
        Object.values(formattedFormForBE.scoresPerDomain).reduce((acc, curr) => (acc += curr), 0) /
        NO_OF_WELLNESS_DOMAINS;

      await createAssessmentResultPost({ assessmentId: screenerId, totalScore, ...formattedFormForBE });

      const scores = await lastScoresGet();
      setUserScores(scores);

      return scores;
    },
    onSuccess: (scores: THealthDomainScores) => {
      queryClient.invalidateQueries({ queryKey: ['assessments-result-average-scores'] });

      navigate({
        to: '/user/dashboard',
      });

      toast({
        title: 'Success',
        description: 'Result saved successfully',
        status: 'success',
      });

      onSuccessCallback?.();

      window.scrollTo({ top: 0, behavior: 'instant' });

      trackEvent(mixpanelEvents.SCREENER_FINISH, {
        screenerId: screenerId,
        screenerSlug: slug,
        scores,
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate, isSuccess };
};

export default useAssessmentResult;
