import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { TSupportTeamMember } from '@/types/User.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import RequestATherapySessionModalTrigger from '../../RequestATherapySessionModalTrigger';

type Props = {
  member: TSupportTeamMember;
};

const TherapistCardContent = ({ member }: Props) => {
  const navigate = useNavigate();

  return (
    <VStack width={'100%'} gap={'20px'}>
      <HStack gap={'20px'} width={'100%'}>
        <RequestATherapySessionModalTrigger
          backgroundColor="primary.500"
          labelColor="primary.500"
          triggerButtonWidth="100%"
          therapist={{
            id: member.id,
            // TODO: Update Designations
            designation: [],
            name: member.name,
            profilePhoto: member.profileImage,
          }}
          noShadow
        />
        <CustomButton
          isTransparent
          label={'More Therapists'}
          onClick={() => navigate({ to: `/directory/${member.type}` })}
          backgroundColor={colors.text.mediumBlue}
          labelColor={colors.text.mediumBlue}
          style={{ border: `2px solid ${colors.text.mediumBlue}`, width: '100%' }}
          hoverBackgroundColor={colors.text.mediumBlue}
          labelHoverColor={colors.extra.white}
          iconAfterLabelSvgPath={svgIcons.rightArrow}
          iconAfterLabelSvgColor={colors.text.mediumBlue}
          iconAfterLabelSvgSize={14}
        />
      </HStack>
      <VStack flex={1} alignItems={'flex-start'} width={'100%'} gap={'4px'}>
        <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
          BIO
        </Text>
        <Text
          height={'100%'}
          width={'100%'}
          whiteSpace={'pre-wrap'}
          variant={'urbanistSemiBoldRegular'}
          color={'text.mediumBlue'}
          noOfLines={14}
        >
          {member?.bio ?? 'No Bio'}
        </Text>
      </VStack>
    </VStack>
  );
};

export default TherapistCardContent;
