import iconsPng from '@/assets/img/png/icons';
import {
  READINESS_DOMAIN,
  READINESS_SUBDOMAINS,
  WELLNESS_DOMAINS_DISPLAY_LABELS,
  WELLNESS_DOMAINS_LIST,
  WELLNESS_SUBDOMAINS_PER_DOMAIN,
} from '@/constants';
import { THealthDomain, THealthSubDomain } from '@/types/Health.types';
import { capitalize } from '@/utils/stringUtils';

export const SUBDOMAIN_SCORES_FORM_KEY = 'subdomainScores';

export type TQuestionAndScoreDescription = { questionDescription: string; scoreDescription: string };

export const QUESTION_AND_SCORE_DESCRIPTION_PER_DOMAIN: Record<THealthDomain, TQuestionAndScoreDescription> = {
  mental: {
    questionDescription:
      'Reflecting on your overall mental state, including your ability to manage stress, maintain emotional balance, and feel psychologically resilient, how would you rate your mental wellness? This encompasses your capacity to cope with everyday challenges, maintain a balanced mood, and feel mentally strong and adaptable.',
    scoreDescription:
      'On a scale from 1 to 10, where 1 means you often feel overwhelmed, emotionally unstable, or struggle to cope with daily stressors, and 10 means you have excellent stress management skills, emotional stability, and feel mentally robust and resilient, how would you rate your mental wellness?',
  },
  physical: {
    questionDescription:
      'Please rate your physical wellness by considering the following aspects: sleep quality, nutritional habits, exercise frequency, pain levels, substance use, and regularity of visits with a healthcare provider for preventive and ongoing care.',
    scoreDescription:
      'On a scale from 1 to 10, with 1 indicating poor physical wellness characterized by numerous health issues and 10 representing excellent physical wellness with minimal or no health concerns, how would you rate your physical wellness?',
  },
  social: {
    questionDescription:
      'Reflecting on your relationships and social interactions, how would you rate your social wellness? This encompasses your ability to form satisfying interpersonal relationships and engage in a supportive social network.',
    scoreDescription:
      'On a scale from 1 to 10, where 1 means you often feel socially isolated or unsatisfied with your interpersonal connections, and 10 means you have strong, fulfilling relationships and an active social life, how would you rate your social wellness?',
  },
  spiritual: {
    questionDescription:
      "Reflecting on your sense of purpose and the meaning you derive from life's activities, how would you rate your wellness in this area? Consider how well your daily activities align with your larger life goals and values.",
    scoreDescription:
      'On a scale from 1 to 10, where 1 indicates a lack of purpose or conflict with personal values, and 10 represents a strong sense of meaning, purpose, and alignment with your beliefs, how would you rate your spiritual wellness?',
  },
  vocational: {
    questionDescription:
      'Considering your current financial position, sense of purpose, and career satisfaction how would you rate your vocational wellness?',
    scoreDescription:
      'On a scale from 1 to 10, where 1 indicates significant dissatisfaction with your job, a lack of meaningful contribution, and high levels of financial stress, and 10 represents financial contentment, complete fulfillment and engagement in your work, how would you rate your vocational wellness?',
  },
  purpose: {
    questionDescription:
      "Reflecting on your sense of purpose and the meaning you derive from life's activities, how would you rate your wellness in this area? Consider how well your daily activities align with your larger life goals and values.",
    scoreDescription:
      'On a scale from 1 to 10, where 1 indicates a lack of direction or purpose and 10 signifies a strong sense of meaning and fulfillment in life, how would you rate your sense of purpose and meaning?',
  },
  fun: {
    questionDescription:
      'Considering your engagement in activities that bring you joy and relaxation, how would you rate your wellness in terms of fun? This includes your ability to enjoy life and take time for activities that you find enjoyable and relaxing.',
    scoreDescription:
      'On a scale from 1 to 10, where 1 indicates minimal engagement in enjoyable activities and 10 represents frequent participation in fun and fulfilling activities, how would you rate your level of fun?',
  },
};

export const READINESS_QUESTIONS_AND_SCORE_DESCRIPTIONS: TQuestionAndScoreDescription = {
  questionDescription: 'Consider your willingness, confidence, importance, motivation and desire to change.',
  scoreDescription:
    'On a scale from 1 to 10, how would you rate your readiness to take steps toward making positive changes in your life in the past 30 days?',
};

export type TSubdomainQuestion = {
  text: string;
  textMin: string;
  textMax: string;
  inputId: string;
};

export const SUBDOMAIN_QUESTIONS: Record<string, Record<THealthSubDomain, TSubdomainQuestion[]>> = {
  mental: {
    FEELING_SAD_OR_DOWN: [
      {
        text: 'I feel sad and alone.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_sad_or_down_1',
      },
      {
        text: 'I tend to have negative feelings about myself.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_sad_or_down_2',
      },
      {
        text: 'I do not like myself.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_sad_or_down_3',
      },
    ],
    UNDERSTANDING_OTHERS_FEELINGS: [
      {
        text: 'I am good at reading the emotions of others.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_understanding_others_feelings_1',
      },
      {
        text: 'Other people tend to confide in me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_understanding_others_feelings_2',
      },
      {
        text: 'I pay attention to the emotions of those around me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_understanding_others_feelings_3',
      },
    ],
    FEELING_NERVOUS_OR_WORRIED: [
      {
        text: 'I feel worried a lot.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_nervous_or_worried_1',
      },
      {
        text: 'I am often frightened about what will happen in the future.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_nervous_or_worried_2',
      },
      {
        text: 'I am typically a confident person.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_feeling_nervous_or_worried_3',
      },
    ],
    MANAGING_EMOTIONS: [
      {
        text: 'I am good at recognizing the emotions I feel.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_managing_emotions_1',
      },
      {
        text: 'Sometimes I have a hard time expressing my emotions.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_managing_emotions_2',
      },
      {
        text: 'I rarely let my emotions get the best of me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_managing_emotions_3',
      },
    ],
    DEALING_WITH_TRAUMA: [
      {
        text: 'I have repeated, disturbing memories or thoughts about particularly stressful experiences in my life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_dealing_with_trauma_1',
      },
      {
        text: 'When I think about particularly stressful experiences, I experience physical symptoms such as trouble breathing, sweating, or other discomfort.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_dealing_with_trauma_2',
      },
      {
        text: 'I sometimes feel as though I am reliving or re-experiencing a particularly stressful event from my life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_dealing_with_trauma_3',
      },
    ],
    TROUBLE_DOING_DAILY_ACTIVITIES: [
      {
        text: 'I have a hard time getting daily tasks done.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_trouble_doing_daily_activities_1',
      },
      {
        text: 'I often miss work or other scheduled appointments due to my mental health.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_trouble_doing_daily_activities_2',
      },
      {
        text: 'I have a hard time getting through the day.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_trouble_doing_daily_activities_3',
      },
    ],
    FEELING_OVERWHELMED: [
      {
        text: 'I often find moments of peace, contentment, or relaxation in my daily life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'mental_feeling_overwhelmed_1',
      },
      {
        text: 'I feel like there aren’t enough hours in the day to accomplish everything I need to do.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_overwhelmed_2',
      },
      {
        text: 'I feel overwhelmed or unable to cope with responsibilities related to my family, work or personal relationships.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_feeling_overwhelmed_3',
      },
    ],
    SUICIDE: [
      {
        text: 'I am thinking about suicide.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_suicide_1',
      },
      {
        text: 'I have had thoughts about killing myself.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_suicide_2',
      },
      {
        text: 'I have a plan to kill myself.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'mental_suicide_3',
      },
    ],
    OTHER_MENTAL: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'mental_other' }],
  },
  physical: {
    PHYSICAL_ACTIVITY: [
      {
        text: 'I regularly get physical exercise.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_physical_activity_1',
      },
      {
        text: 'I would consider myself physically active.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_physical_activity_2',
      },
      {
        text: 'I rarely get much physical exercise.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_physical_activity_3',
      },
    ],
    HEALTH_CHECKUPS: [
      {
        text: 'I see my doctor on a regular basis.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_health_checkups_1',
      },
      {
        text: 'I tend to only see a doctor if there is an urgent need or concern I have with my health.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_health_checkups_2',
      },
      {
        text: 'I get an annual check-up or physical at least once a year.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_health_checkups_3',
      },
    ],
    BODY_ACHES_OR_PAINS: [
      {
        text: 'How much physical pain do you experience on a daily basis?',
        textMin: '1 - Very Severe Pain',
        textMax: 'No Pain - 10',
        inputId: 'physical_body_aches_or_pains_1',
      },
    ],
    SLEEP_PATTERNS: [
      {
        text: 'I get adequate sleep each night.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_sleep_patterns_1',
      },
    ],
    EATING_HABITS: [
      {
        text: 'I have a hard time staying away from unhealthy foods.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_eating_habits_1',
      },
      {
        text: 'I tend to eat a balanced and healthy diet.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_eating_habits_2',
      },
      {
        text: 'I try to avoid fatty and sugary foods.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'physical_eating_habits_3',
      },
    ],
    ALCOHOL_AND_OTHER_DRUG_USE: [
      {
        text: 'I have felt I should cut down on my drinking or drug use.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_alcohol_and_other_drug_use_1',
      },
      {
        text: 'I have felt annoyed by people who criticize my drinking or drug use.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_alcohol_and_other_drug_use_2',
      },
      {
        text: 'I have felt bad or guilty about my drinking or drug use.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_alcohol_and_other_drug_use_3',
      },
      {
        text: 'I have drank or used drugs first thing in the morning to steady my nerves or to get rid of a hangover.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_alcohol_and_other_drug_use_4',
      },
    ],
    COMPULSIVE_BEHAVIORS_EXCLUDING_ALCOHOL_AND_DRUGS: [
      {
        text: 'I engage in behaviors (such as video gaming, viewing pornography, or gambling) that I feel I cannot control or that negatively impact my daily life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'physical_compulsive_behaviors_excluding_alcohol_and_drugs_1',
      },
    ],
    OTHER_PHYSICAL: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'physical_other' }],
  },
  social: {
    FEELING_CLOSE_TO_PEOPLE: [
      {
        text: 'I often avoid close relationships because I worry I will get hurt.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_feeling_close_to_people_1',
      },
      {
        text: 'I worry a lot about what others think of me.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_feeling_close_to_people_2',
      },
      {
        text: 'In a relationship, I often worry that the other person doesn’t like me.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_feeling_close_to_people_3',
      },
    ],
    RELATIONSHIP_WITH_PARENTS_AND_SIBLINGS: [
      {
        text: 'I have good relationships with my parents and siblings.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_parents_and_siblings_1',
      },
      {
        text: 'My parents and siblings are an important resource in my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_parents_and_siblings_2',
      },
      {
        text: 'My relationship with my parents and siblings is strained.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_relationship_with_parents_and_siblings_3',
      },
    ],
    FAMILY_TROUBLES_GROWING_UP: [
      {
        text: 'Events in my childhood continue to negatively impact my life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_family_troubles_growing_up_1',
      },
      {
        text: 'I have a hard time coming to terms with events in my childhood.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_family_troubles_growing_up_2',
      },
      {
        text: 'I still feel anxiety about things that occurred when I was a child.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_family_troubles_growing_up_3',
      },
    ],
    WITHDRAWING_FROM_PEOPLE: [
      {
        text: 'I enjoy hanging out with other people.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_withdrawing_from_people_1',
      },
      {
        text: 'I have lots of friends.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_withdrawing_from_people_2',
      },
      {
        text: 'If I had a problem, there are a lot of friends I could turn to.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_withdrawing_from_people_3',
      },
    ],
    SUPPORT_FROM_FRIENDS_FAMILY_OR_COMMUNITY: [
      {
        text: 'I know how to access community services when I need assistance.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_support_from_friends_family_or_community_1',
      },
      {
        text: 'My family provides me with the resources I need during difficult times.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_support_from_friends_family_or_community_2',
      },
      {
        text: 'I have access to support from friends when I need help.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_support_from_friends_family_or_community_3',
      },
    ],
    ANGER: [
      {
        text: 'I frequently experience explosive outbursts (screaming, making threats, throwing objects, or other acts of intense anger).',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_anger_1',
      },
      {
        text: 'My anger often causes problems in my relationships with others.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_anger_2',
      },
      {
        text: 'I  can remain calm when things don’t go my way.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_anger_3',
      },
    ],
    RELATIONSHIP_WITH_CHILDREN: [
      {
        text: 'I feel that I have open and meaningful communication with my child(ren) on a regular basis.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_children_1',
      },
      {
        text: "I am confident in my ability to support my child(ren)'s emotional and developmental needs.",
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_children_2',
      },
      {
        text: "I don't spend enough quality time with my child(ren) to build a strong and positive relationship.",
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_relationship_with_children_3',
      },
    ],
    RELATIONSHIP_WITH_SPOUSE_OR_PARTNER: [
      {
        text: 'I am satisfied with my current romantic relationship overall.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_spouse_or_partner_1',
      },
      {
        text: 'I am happy with the direction my romantic relationship is going.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_spouse_or_partner_2',
      },
      {
        text: 'I am satisfied with how my romantic partner treats and talks to me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_relationship_with_spouse_or_partner_3',
      },
    ],
    COMMITMENT_TO_SPOUSE_OR_PARTNER: [
      {
        text: 'I have thought about ending my romantic relationship.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_commitment_to_spouse_or_partner_1',
      },
      {
        text: 'I sometimes wonder what it would be like to be in a romantic relationship with someone other than my current partner.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'social_commitment_to_spouse_or_partner_2',
      },
      {
        text: 'I want to stay with my current romantic partner for the rest of my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'social_commitment_to_spouse_or_partner_3',
      },
    ],
    OTHER_SOCIAL: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'social_other' }],
  },
  spiritual: {
    LACK_OF_SPIRITUAL_DEVOTION: [
      {
        text: 'I read holy writing or scripture regularly.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_lack_of_spiritual_devotion_1',
      },
      {
        text: 'I attend church service or engage in personal spiritual practices weekly.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_lack_of_spiritual_devotion_3',
      },
      {
        text: 'People around me know me as a spiritual or religious person.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_lack_of_spiritual_devotion_3',
      },
    ],
    DISCONNECTED_FROM_SPIRITUALITY: [
      {
        text: 'I feel guided by a higher power in my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_disconnected_from_spirituality_1',
      },
      {
        text: 'I have a set of spiritual or religious beliefs that guide my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_disconnected_from_spirituality_2',
      },
      {
        text: 'My spirituality or religion impacts most aspects of my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'spiritual_disconnected_from_spirituality_3',
      },
    ],

    OTHER_SPIRITUAL: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'spiritual_other' }],
  },
  vocational: {
    RELATIONSHIP_WITH_EMPLOYER: [
      {
        text: "I am satisfied with my employer's benefits package.",
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_relationship_with_employer_1',
      },
      {
        text: 'I am satisfied with the working atmosphere and relationships with my peers and supervisors.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_relationship_with_employer_2',
      },
      {
        text: 'I am satisfied with the training and educational resources provided by my employer.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_relationship_with_employer_3',
      },
    ],
    BALANCING_WORK_AND_PERSONAL_LIFE: [
      {
        text: 'My work sometimes overtakes everything else in my life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'vocational_balancing_work_and_personal_life_1',
      },
      {
        text: 'I have found a good balance between my work life and personal life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_balancing_work_and_personal_life_2',
      },
      {
        text: 'I have a hard time letting go of work when I get home.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'vocational_balancing_work_and_personal_life_3',
      },
    ],
    MANAGING_MONEY: [
      {
        text: 'I am satisfied with my ability to manage my monthly finances, for example adhering to a budget, tracking spending, or managing day-to-day expenses.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_managing_money_1',
      },
      {
        text: 'I am satisfied with my managing and reducing debt, including credit cards, loans, and mortgages.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_managing_money_2',
      },
      {
        text: 'I am satisfied with my progress toward long-term financial goals, for example retirement, purchasing a home, or having adequate insurance coverage.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_managing_money_3',
      },
    ],
    JOB_SATISFACTION: [
      {
        text: 'I am very happy with where my career is currently at.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'vocational_job_satisfaction_1',
      },
      {
        text: 'I wish I was able to change my career path.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'vocational_job_satisfaction_2',
      },
      {
        text: 'I am unhappy with my current work situation.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'vocational_job_satisfaction_3',
      },
    ],
    OTHER_VOCATIONAL_FINANCIAL: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'vocational_other' }],
  },
  purpose: {
    LIFE_SATISFACTION: [
      {
        text: 'I am satisfied with my life overall.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'purpose_life_satisfaction_1',
      },
      {
        text: 'In most aspects of my life, I am happy.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'purpose_life_satisfaction_2',
      },
      {
        text: 'I wish my life was different.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'purpose_life_satisfaction_3',
      },
    ],
    STUCK_OR_UNMOTIVATED: [
      {
        text: 'I often feel unmotivated or stuck in my current situation.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'purpose_stuck_or_unmotivated_1',
      },
      {
        text: 'I have clear goals and a sense of direction in my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'purpose_stuck_or_unmotivated_2',
      },
      {
        text: 'There are a lot of things that hold me back from pursuing my goals or dreams.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'purpose_stuck_or_unmotivated_3',
      },
    ],
    DETERMINATION_AND_RESILIENCE: [
      {
        text: 'I can generally recover from stressful things quickly.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'purpose_determination_and_resilience_1',
      },
      {
        text: 'It takes me a long time to get over stressful events in my life.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'purpose_determination_and_resilience_2',
      },
      {
        text: 'I usually have the resources I need to make it through hard periods in my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'purpose_determination_and_resilience_3',
      },
    ],
    OTHER_PURPOSE_RESILIENCE: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'purpose_other' }],
  },
  fun: {
    LACK_OF_HOBBIES: [
      {
        text: 'I participate in enjoyable hobbies often.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_lack_of_hobbies_1',
      },
      {
        text: 'I try to do at least one thing a day that I enjoy doing.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_lack_of_hobbies_2',
      },
      {
        text: 'I have no or few hobbies that I participate in.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_lack_of_hobbies_3',
      },
    ],
    BORED_WITH_ROUTINES: [
      {
        text: "I often feel bored because I'm doing the same things over and over again.",
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_bored_with_routines_1',
      },
      {
        text: 'I often find myself looking forward to breaks or time off from my routine.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_bored_with_routines_2',
      },
      {
        text: 'I usually feel interested and excited about what I do every day.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_bored_with_routines_3',
      },
    ],
    STRUGGLES_WITH_SELF_CARE: [
      {
        text: 'I often prioritize activities that improve my physical health, such as exercise or healthy eating.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_struggles_with_self_care_1',
      },
      {
        text: 'I rarely take time for relaxation and stress relief, such as meditation or deep breathing exercises.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_struggles_with_self_care_2',
      },
      {
        text: 'I have a good work-life balance, which helps me feel happy and rested.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_struggles_with_self_care_3',
      },
    ],
    NO_TIME_TO_RELAX: [
      {
        text: 'I have moments of enjoyment each day.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'fun_no_time_to_relax_1',
      },
      {
        text: 'I rarely have time for myself during the day.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_no_time_to_relax_2',
      },
      {
        text: 'I rarely have fun during a typical day.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'fun_no_time_to_relax_3',
      },
    ],
    OTHER_FUN_RELAXATION: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'fun_other' }],
  },

  readiness: {
    NOT_IMPORTANT: [
      {
        text: 'I never spend time thinking about making a change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_not_important_1',
      },
      {
        text: 'I am becoming more certain that change is something I need.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_not_important_2',
      },
      {
        text: 'I am realizing that making change matters to me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_not_important_3',
      },
    ],
    SCARED_OF_CHANGE: [
      {
        text: 'I am beginning to feel more open to making a change.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_scared_of_change_1',
      },
      {
        text: 'It’s hard for me to manage my fears about change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_scared_of_change_2',
      },
      {
        text: 'My fear of failure often stops me from taking the first step towards change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_scared_of_change_3',
      },
    ],
    MENTAL_HEALTH_CONCERNS: [
      {
        text: 'Mental health concerns keep me from making a change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_mental_health_concerns_1',
      },
      {
        text: 'My mental health struggles make it difficult for me to initiate new changes in my life',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_mental_health_concerns_2',
      },
      {
        text: 'I’m confident in the ways I cope with stress and emotions.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_mental_health_concerns_3',
      },
    ],
    DO_NOT_FEEL_SUPPORTED: [
      {
        text: 'No one seems available to support me through change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_do_not_feel_supported_1',
      },
      {
        text: 'Others support me  in making changes in my life.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_do_not_feel_supported_2',
      },
      {
        text: 'I feel confident that I can find the support I need when making changes.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_do_not_feel_supported_3',
      },
    ],
    DO_NOT_KNOW_HOW: [
      {
        text: 'I believe that with the right knowledge and time, I can make progress.',
        textMin: '1 - Strongly Disagree ',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_do_not_know_how_1',
      },
      {
        text: 'I am taking steps to learn what I need to make a change.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_do_not_know_how_2',
      },
      {
        text: 'I need more information and guidance to feel confident in making changes.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_do_not_know_how_3',
      },
    ],

    DO_NOT_HAVE_TIME: [
      {
        text: 'I don’t know how to set aside time to make important changes.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_do_not_have_time_1',
      },
      {
        text: 'My schedule is so busy I don’t have time to make needed for change.',
        textMin: '1 - Absolutely Agree',
        textMax: 'Strongly Disagree - 10',
        inputId: 'readiness_do_not_have_time_2',
      },
      {
        text: 'I can always find time for the changes that are important to me.',
        textMin: '1 - Strongly Disagree',
        textMax: 'Absolutely Agree - 10',
        inputId: 'readiness_do_not_have_time_3',
      },
    ],

    OTHER_READINESS: [{ text: 'Enter', textMin: 'min', textMax: 'max', inputId: 'readiness_other' }],
  },
};

type TDomainQuestionPage = {
  domain: string;
  title: string;
  imageProps: {
    src: string;
    alt: string;
  };
  subdomains: readonly string[];
} & TQuestionAndScoreDescription;

const QUESTIONS_FOR_READINESS = {
  domain: READINESS_DOMAIN,
  title: capitalize(READINESS_DOMAIN),
  imageProps: {
    src: iconsPng.wellnessDomainsPurple.readiness,
    alt: READINESS_DOMAIN,
  },
  ...READINESS_QUESTIONS_AND_SCORE_DESCRIPTIONS,
  subdomains: READINESS_SUBDOMAINS,
};

export const QUESTIONS_PER_DOMAIN_AND_READINESS: TDomainQuestionPage[] = [
  ...WELLNESS_DOMAINS_LIST.map((domain) => ({
    domain,
    title: `${WELLNESS_DOMAINS_DISPLAY_LABELS[domain]} Wholeness`,
    imageProps: {
      src: iconsPng.wellnessDomainsPurple[domain],
      alt: domain,
    },
    ...QUESTION_AND_SCORE_DESCRIPTION_PER_DOMAIN[domain],
    subdomains: WELLNESS_SUBDOMAINS_PER_DOMAIN[domain],
  })),
  QUESTIONS_FOR_READINESS,
];

export const WELLNESS_SUBDOMAINS_PER_DOMAIN_AND_READINESS = {
  ...WELLNESS_SUBDOMAINS_PER_DOMAIN,
  readiness: READINESS_SUBDOMAINS,
};
