import CardHeaderWithImageAndName from '@/components/atoms/CardHeaderWithImageAndName';
import { Card, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React, { PropsWithChildren } from 'react';

type Props = {
  pictureUrl?: string;
  pictureLink?: string;
  routeParams?: Record<string, string>;
  name: string;
  roleSubtitle?: string;
};

const CARD_WIDTH = '295px';
const CARD_HEIGHT = '400px';

const DirectoryCardBase = ({
  pictureUrl,
  pictureLink,
  routeParams,
  name,
  roleSubtitle,
  children,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  return (
    <Card
      size="sm"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      variant={'elevated'}
      borderRadius={'8px'}
      padding={'20px'}
      shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
    >
      <CardHeaderWithImageAndName
        onClick={() => {
          console.log('pictureLink', pictureLink);
          console.log('routeParams', routeParams);

          navigate({ to: pictureLink, params: { ...routeParams } });
        }}
        hasTooltip
        profilePicture={pictureUrl}
        name={name}
        nameSubtitle={roleSubtitle}
      />
      <VStack flex={1}>{children}</VStack>
    </Card>
  );
};

export default DirectoryCardBase;
