import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SliderExtended from '.';
import { useScreenerStore } from '@/store/useScreenerStore';

type Props = {
  name: string;
  labelStart?: string;
  labelEnd?: string;
  healthDomain: string;
  isAnswered?: boolean;
  onChangeExternal?: (_: number) => void;
  question?: string;
  inputId?: string; // keeping this param for old screener's sake
};

const ControlledSliderExtended = ({
  labelStart = 'Less',
  labelEnd = 'More',
  name,
  healthDomain,
  question,
  inputId,
}: Props) => {
  const { registerField } = useScreenerStore();

  useEffect(() => {
    registerField(name, healthDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <SliderExtended
            value={value?.value}
            setValue={(val) => {
              const valueObject: any = { value: val };
              if (inputId) {
                valueObject.inputId = inputId;
              }
              if (question) {
                valueObject.question = question;
              }

              onChange(valueObject);
            }}
            labelStart={labelStart}
            labelEnd={labelEnd}
          />
        );
      }}
    />
  );
};

export default ControlledSliderExtended;
