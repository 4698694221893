import { TCustomAxiosError } from '@/services/api';
import { TUserSendEmailData, TUserSendEmailPeerSupportOrMemberCare } from '@/types/User.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from './useToast';
import { sendEmail } from '@/services/api/requests/contact';

const useSendEmail = ({
  peerSupportOrMemberCare,
}: {
  peerSupportOrMemberCare: TUserSendEmailPeerSupportOrMemberCare;
}): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { userSendEmailData: TUserSendEmailData }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ userSendEmailData }: { userSendEmailData: TUserSendEmailData }) => {
      sendEmail(peerSupportOrMemberCare, userSendEmailData);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: `${peerSupportOrMemberCare === 'member-care' ? 'Member Care' : 'Peer Support'} contacted successfully.`,
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Email contact error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

export default useSendEmail;
