import useTherapistsDirectory from '@/hooks/therapist/useTherapistsDirectory';
import TherapistDirectoryCard from '@/modules/directory/cards/TherapistDirectoryCard';
import DirectoryPageTemplate from '@/modules/directory/DirectoryPageTemplate';
import { createFileRoute } from '@tanstack/react-router';

const TherapistDirectory = () => {
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useTherapistsDirectory();

  return (
    <DirectoryPageTemplate
      title="Available Therapists"
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onViewMoreClick={fetchNextPage}
    >
      {data?.map((therapist) => <TherapistDirectoryCard member={therapist} key={therapist?._id} />)}
    </DirectoryPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_therapist/directory/therapist')({
  component: TherapistDirectory,
});
