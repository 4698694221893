import AppPageTemplate from '@/components/templates/AppPageTemplate';
import useDocumentById from '@/hooks/documents/useDocumentById';
import ScreenerContainer from '@/modules/screener/ScreenerContainer';
import { useAppStore } from '@/store/useAppStore';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { Text, VStack } from '@chakra-ui/react';
import { createFileRoute, redirect } from '@tanstack/react-router';

const WHOLENESS_SCREENER_SLUG_V2 = 'wholeness-screener-2.0';

const ScreenerPage = () => {
  // const { screenerId } = Route.useParams();

  const { isError } = useDocumentById(WHOLENESS_SCREENER_SLUG_V2); // sets the screenerId and slug to the store as a side effect

  return (
    <AppPageTemplate>
      {isError ? (
        <VStack>
          <Text marginY={'40px'} variant={'loraSectionTitle'}>
            Something went wrong.
          </Text>
          <Text marginY={'20px'} variant={'loraSectionTitle'}>
            Please go back or reload the page.
          </Text>
        </VStack>
      ) : (
        <ScreenerContainer />
      )}
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/screener/$screenerId')({
  component: ScreenerPage,
  beforeLoad: async ({ context: { auth } }) => {
    const isOrgAdmin = auth?.isAdmin;
    const { onboardingStatuses, isEulaAccepted } = useAppStore.getState();

    const isOnboardingCompleted = isEverythingSetup(onboardingStatuses, isOrgAdmin);

    if (!isOnboardingCompleted) {
      if (isOrgAdmin) {
        throw redirect({
          to: '/onboarding/organization',
        });
      }

      throw redirect({
        to: '/onboarding/user',
      });
    }

    if (!isEulaAccepted) {
      throw redirect({
        to: '/eula/update',
      });
    }
  },
});
