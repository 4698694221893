export const APP_NAME = 'MyOmnia';
export const APP_DESCRIPTION = 'Empowering Your Wholeness';
export const HARDCODED_APP_VERSION = 'v1.0.0'; // TODO: hardcoded until we decide on app versioning

export const CALENDLY_ROOT_ELEMENT_ID = '__calendly';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^(?:\+?\d{1,2}\s?)?(\(?\d{2,3}\)?[\s.\-]?)\d{3}[\s\-]?\d{3,4}$/;

export const NONE_OF_THE_ABOVE_LABEL = 'None of the above';
export const MODAL_QUESTION_FORM_KEY = 'modal_form_key';

export const HIGH_CONCERN_LABEL = 'High Concern';
export const SOME_CONCERN_LABEL = 'Some Concern';
export const LOW_CONCERN_LABEL = 'Low Concern';

export const NO_CHANGE_CONCERN_LABEL = 'No change';

export const OTHER_PLEASE_SPECIFY = 'Other (please specify)';
