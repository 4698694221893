import { THealthDomainScores } from '@/types/Health.types';
import { api, TCustomAxiosResponse } from '..';
import { TScreenerFormForBE } from '@/utils/screenersScores';

const root = '/v1/assessments/result';

const endpoints = {
  allResults: `${root}`,
  myLastScores: `${root}/last`,
  create: `${root}/create`,
};

export const lastScoresGet = async (): Promise<THealthDomainScores> => {
  const { data } = await api.get(endpoints.myLastScores);
  return data.data;
};

export const createAssessmentResultPost = async ({
  assessmentId,
  totalScore,
  scoresPerDomain,
  subdomainScores,
  readiness,
  critical,
  domainQuestionsWithValues,
}: TScreenerFormForBE & {
  assessmentId: string;
  totalScore: number;
}): Promise<unknown> => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(endpoints.create, {
    assessmentId,
    totalScore,
    scores: scoresPerDomain,
    subdomainScores,
    readiness,
    critical,
    data: domainQuestionsWithValues,
  });

  const response = data.data;

  return response;
};
