import iconsPng from '@/assets/img/png/icons';
import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import ModalContainer from '@/components/molecules/ModalContainer';
import useTherapyRequest from '@/hooks/therapist/useTherapyRequest';
import colors from '@/theme/colors';
import { displayCapitalizedUnderscoredString } from '@/utils/stringUtils';
import { Center, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type Props = {
  triggerButtonWidth?: string;
  backgroundColor?: string;
  labelColor?: string;
  hoveredBackgroundColor?: string;
  hoveredLabelColor?: string;
  triggerButtonTransparent?: boolean;
  noShadow?: boolean;
  therapist: {
    id: string;
    designation: string[];
    name: string;
    profilePhoto: string;
  };
};

const CONTENT_WIDTH = '380px';
const MODAL_WIDTH = '580px';

const RequestATherapySessionModalTrigger = ({
  triggerButtonWidth = '340px',
  labelColor = colors.primary[500],
  hoveredBackgroundColor = colors.primary[500],
  triggerButtonTransparent = false,
  noShadow = false,
  therapist,
}: Props) => {
  const [isRequestModalVisible, setIsRequestModalVisible] = React.useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = React.useState(false);

  const {
    isPending,
    mutate: makeRequest,
    isSuccess,
  } = useTherapyRequest(therapist.id, () => {
    setIsSuccessModalVisible(true);
    setIsRequestModalVisible(false);
  });

  return (
    <>
      <CustomButton
        isTransparent={triggerButtonTransparent}
        label="Schedule Call"
        backgroundColor={'white'}
        labelColor={labelColor}
        onClick={() => {
          setIsRequestModalVisible(true);
        }}
        width={triggerButtonWidth}
        style={{ border: `2px solid ${triggerButtonTransparent ? 'transparent' : colors.primary[500]}` }}
        hoverBackgroundColor={hoveredBackgroundColor}
        labelHoverColor={triggerButtonTransparent ? colors.primary[500] : 'white'}
        iconBeforeLabelJSX={<SvgIconCalendar color={colors.primary[500]} size={18} />}
        iconBeforeLabelHoveredJSX={
          <SvgIconCalendar color={triggerButtonTransparent ? colors.primary[500] : 'white'} size={18} />
        }
        noShadow={noShadow}
        disabled={isSuccess}
      />
      <ModalContainer
        isOpen={isRequestModalVisible}
        onClose={() => {
          setIsRequestModalVisible(false);
        }}
        isBlur={false}
        width={MODAL_WIDTH}
        backgroundColor={'background.lightBlue'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'22px'} marginTop={'12px'}>
          Request a Therapy Session
        </Text>
        <VStack flex={1} alignItems={'center'} marginBottom={'36px'}>
          <VStack
            width={CONTENT_WIDTH}
            padding={'30px'}
            marginTop={'40px'}
            boxShadow={'0px 8px 40px 0px #0000001A'}
            backgroundColor={'white'}
            borderRadius={'10px'}
            justifyContent={'center'}
          >
            <VStack
              maxWidth={'100px'}
              maxHeight={'100px'}
              borderWidth={'4px'}
              borderRadius={'full'}
              borderColor={'white'}
              shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
              overflow={'hidden'}
              cursor={'pointer'}
              _groupHover={{ boxShadow: '0px 4px 8px 0px rgba(14, 0, 98, 0.2)' }}
              transition={'box-shadow 0.1s'}
            >
              {therapist?.profilePhoto ? (
                <Image
                  src={therapist?.profilePhoto}
                  alt={therapist.name}
                  width={100}
                  height={100}
                  borderRadius={'full'}
                  objectFit={'cover'}
                  backgroundColor={'white'}
                />
              ) : (
                <Center backgroundColor={'#EDE8FD'} height={100} width={100}>
                  <SvgIcon color={'#fff'} iconPath={svgIcons.userSeeThrough} size={60} boxHeight={80} boxWidth={80} />
                </Center>
              )}
            </VStack>
            <VStack marginTop={'20px'} gap={'6px'}>
              <Text
                variant={'lora'}
                fontSize={'22px'}
                lineHeight={'22px'}
                fontWeight={500}
                color={'text.darkBlue'}
                fontStyle={'italic'}
                noOfLines={2}
                maxWidth={'250px'}
                textAlign={'center'}
              >
                {therapist.name}
              </Text>
              <Text variant={'urbanist'} lineHeight={'18px'} color={'text.mediumGray'}>
                Therapist
              </Text>
              <Text
                variant={'urbanist'}
                fontWeight={'700'}
                fontSize={'14px'}
                lineHeight={'18px'}
                color={'text.darkBlue'}
                textAlign={'center'}
              >
                {therapist.designation.map((d) => displayCapitalizedUnderscoredString(d)).join(', ')}
              </Text>
            </VStack>
          </VStack>
          <VStack width={CONTENT_WIDTH} marginY={'40px'} paddingX={'4px'}>
            <Text
              textAlign={'center'}
              fontWeight={'500px'}
              fontSize={'18px'}
              lineHeight={'24px'}
              variant={'urbanist'}
              color={'text.mediumBlue'}
            >
              Your Member Care Coordinator is here to help you schedule an appointment with {therapist.name} as soon as
              possible.
            </Text>
            <Text
              textAlign={'center'}
              fontWeight={'500px'}
              fontSize={'18px'}
              lineHeight={'24px'}
              variant={'urbanist'}
              color={'text.mediumBlue'}
            >
              Hit <b>Request</b> to submit your request for services.
            </Text>
          </VStack>
          <CustomButton
            width={'295px'}
            height={'54px'}
            onClick={makeRequest}
            backgroundColor="primary.500"
            labelColor="extra.white"
            label="Request"
            isLoading={isPending}
          />
        </VStack>
      </ModalContainer>
      <ModalContainer
        isOpen={isSuccessModalVisible}
        onClose={() => {
          setIsSuccessModalVisible(false);
        }}
        isBlur={false}
        width={MODAL_WIDTH}
        backgroundColor={'background.lightBlue'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'22px'} marginTop={'12px'}>
          Request Sent
        </Text>
        <VStack marginBottom={'36px'} alignItems={'center'} marginTop={'36px'}>
          <VStack width={CONTENT_WIDTH} gap={'36px'}>
            <Image alt="ok-hand" src={iconsPng.okHand} width={100} height={100} />
            <Text
              textAlign={'center'}
              fontWeight={'500px'}
              fontSize={'18px'}
              lineHeight={'24px'}
              variant={'urbanist'}
              color={'text.mediumBlue'}
            >
              Thank you for requesting to meet with {therapist.name}. Your Member Care Coordinator will reach out to you
              as quick as possible.
            </Text>
          </VStack>
        </VStack>
      </ModalContainer>
    </>
  );
};

export default RequestATherapySessionModalTrigger;
