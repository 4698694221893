import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import colors from '@/theme/colors';
import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const REPRESENTATIONAL_ORG_WHOLENESS_REPORT_DATA = {
  fun: 68,
  spiritual: 66,
  physical: 68,
  purpose: 60,
  vocational: 51,
  social: 27,
  mental: 11,
};

const REPRESENTATIONAL_ORG_WHOLENESS_REPORT_TOTAL_SCORE =
  Object.values(REPRESENTATIONAL_ORG_WHOLENESS_REPORT_DATA).reduce((a, b) => a + b) / 70;

interface Props {
  isVisible: boolean;
  setIsVisible: (_: boolean) => void;
}

const RepresentationalOrgWholenessReportModal = ({ isVisible, setIsVisible }: Props) => {
  return (
    <ModalContainer
      isOpen={isVisible}
      closeOnOverlayClick={false}
      onClose={() => setIsVisible(false)}
      isBlur={false}
      width={'1050px'}
      height={'910px'}
      backgroundColor={'background.lightGray'}
      footerContent={
        <HStack justifyContent={'center'} width={'100%'} height={'100%'} spacing={'10px'} marginBottom={'10px'}>
          <CustomButton
            label="Close Modal"
            backgroundColor="primary.500"
            labelColor="extra.white"
            labelHoverColor={colors.extra.white}
            onClick={() => setIsVisible(false)}
            height={'54px'}
            width={'200px'}
          />
        </HStack>
      }
    >
      <Text variant={'loraMedium'} textAlign={'center'} fontSize={'22px'} marginTop={'20px'} color={'text.darkBlue'}>
        Organization Wholeness Report
      </Text>
      <Text
        variant={'urbanistMediumLarge'}
        color={'text.mediumBlue'}
        width={'100%'}
        textAlign={'center'}
        marginTop={'20px'}
      >
        All the pertinent data - without any personal, compromising details.
      </Text>

      <VStack marginY={'30px'}>
        <WellnessStateCircle
          wellnessData={REPRESENTATIONAL_ORG_WHOLENESS_REPORT_DATA}
          totalScore={REPRESENTATIONAL_ORG_WHOLENESS_REPORT_TOTAL_SCORE}
          showCenteredTextOverlay
        />
      </VStack>

      <HStack marginTop={'20px'} justifyContent={'center'} gap={'40px'}>
        <HStack gap={'14px'}>
          <VStack backgroundColor={'extra.red'} width={'18px'} height={'18px'} borderRadius={'50%'} />
          <Text variant={'urbanistBoldLarge'} color={'text.mediumBlue'} fontSize={'16px'}>
            Opportunities for Growth
          </Text>
        </HStack>
        <HStack gap={'14px'}>
          <VStack backgroundColor={'extra.yellowStrong'} width={'18px'} height={'18px'} borderRadius={'50%'} />
          <Text variant={'urbanistBoldLarge'} color={'text.mediumBlue'} fontSize={'16px'}>
            Developing Potential
          </Text>
        </HStack>
        <HStack gap={'14px'}>
          <VStack backgroundColor={'extra.teal'} width={'18px'} height={'18px'} borderRadius={'50%'} />
          <Text variant={'urbanistBoldLarge'} color={'text.mediumBlue'} fontSize={'16px'}>
            Team Strengths
          </Text>
        </HStack>
      </HStack>
    </ModalContainer>
  );
};

export default RepresentationalOrgWholenessReportModal;
