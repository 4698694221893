import ModalContainer from '@/components/molecules/ModalContainer';
import { Center, Input, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TUserContactMemberCareForm, TUserSendEmailPeerSupportOrMemberCare } from '@/types/User.types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomButton from '@/components/atoms/CustomButton';
import svgIcons from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import useSendEmail from '@/hooks/useSendEmail';

interface Props {
  peerSupportOrMemberCare: TUserSendEmailPeerSupportOrMemberCare;
  recipientId: string;
  toEmailName: string;
  triggerButtonLabel?: string;
  triggerButtonWidth?: string;
  backgroundColor?: string;
  labelColor?: string;
  hoveredBackgroundColor?: string;
  hoveredLabelColor?: string;
  triggerButtonTransparent?: boolean;
  noShadow?: boolean;
}

const SendEmailModalWithTrigger = ({
  peerSupportOrMemberCare,
  recipientId,
  toEmailName,
  triggerButtonLabel = 'Send Email',
  triggerButtonWidth = '340px',
  backgroundColor = colors.extra.white,
  labelColor = colors.primary[500],
  hoveredBackgroundColor = colors.primary[500],
  hoveredLabelColor = colors.extra.white,
  triggerButtonTransparent = false,
  noShadow = false,
}: Props) => {
  const { mutate, isPending } = useSendEmail({ peerSupportOrMemberCare });

  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);

  const { register, formState, reset, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        emailSubject: yup.string().required('Subject is required.').min(5, 'Subject too short'),
        emailContent: yup.string().required('Content is required.').min(10, 'Content too short'),
      }),
    ),
  });

  const { isValid, errors, touchedFields } = formState;

  const [customEmailError, setCustomEmailError] = useState('');

  const onFormSubmit = async (formData: TUserContactMemberCareForm) => {
    try {
      mutate({
        userSendEmailData: { message: formData.emailContent, subject: formData.emailSubject, recipientId },
      });

      setSendEmailModalVisible(false);
      reset();
      setCustomEmailError('');
    } catch (e: any) {
      setCustomEmailError(e.message ?? e.text ?? e.code ?? e);
    }
  };

  return (
    <>
      <CustomButton
        isTransparent={triggerButtonTransparent}
        label={triggerButtonLabel}
        onClick={() => setSendEmailModalVisible(true)}
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        buttonType={isValid ? 'submit' : 'button'}
        width={triggerButtonWidth}
        style={{ border: `2px solid ${triggerButtonTransparent ? labelColor : backgroundColor}` }}
        hoverBackgroundColor={hoveredBackgroundColor}
        labelHoverColor={hoveredLabelColor}
        iconBeforeLabelSvgPath={svgIcons.email}
        iconBeforeLabelSvgColor={labelColor}
        noShadow={noShadow}
      />
      <ModalContainer
        isOpen={sendEmailModalVisible}
        onClose={() => setSendEmailModalVisible(false)}
        isBlur={false}
        width={'420px'}
        height={'20vh'}
        backgroundColor={'background.lightBlue'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'} paddingX={'40px'}>
          Send {toEmailName} an Email
        </Text>
        <Text variant={'urbanistMediumLarge'} textAlign={'center'} color={'text.mediumGray'} marginTop={'20px'}>
          Enter email subject and email content below.
        </Text>
        <Center>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
              <Input
                {...register('emailSubject', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Subject'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                data-state={touchedFields.emailSubject ? (errors.emailSubject?.message ? 'invalid' : 'valid') : ''}
              />
              {errors.emailSubject && <Text variant={'error'}>{errors.emailSubject.message}</Text>}
              <Input
                {...register('emailContent', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Email Content'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                data-state={touchedFields.emailContent ? (errors.emailContent?.message ? 'invalid' : 'valid') : ''}
              />
              {errors.emailContent && <Text variant={'error'}>{errors.emailContent.message}</Text>}
              {customEmailError && <Text variant={'error'}>{customEmailError}</Text>}
              <CustomButton
                style={{ marginTop: '20px' }}
                label="Send Email"
                backgroundColor="primary.500"
                width={FORM_FIELD_WIDTH}
                labelColor="white"
                disabled={!isValid}
                isLoading={isPending}
                buttonType={isValid ? 'submit' : 'button'}
              />
            </VStack>
          </form>
        </Center>
      </ModalContainer>
    </>
  );
};

export default SendEmailModalWithTrigger;
