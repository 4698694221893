import { READINESS_DOMAIN } from '@/constants';
import { TDomainQuestionsWithValues, THealthDomain, THealthDomainQuestionType } from '@/types/Health.types';

const mapSubdomainScoreToBE = (subdomain: any) => {
  return Object.fromEntries(
    Object.entries(subdomain).map(([key, value]) => {
      if (key.toLowerCase().startsWith('other')) {
        return [key, (value as any[])[0]];
      } else {
        return [key, { questions: value }];
      }
    }),
  );
};

export type TScreenerFormForBE = {
  scoresPerDomain: Record<THealthDomain, number>;
  subdomainScores: Record<THealthDomain, any>;
  readiness: any;
  critical: {
    questions: {
      id: number;
      value: boolean;
      question: string;
      text?: string;
    }[];
  };
  domainQuestionsWithValues: TDomainQuestionsWithValues;
};

export const formatFormForBE = (
  formValues: Record<
    THealthDomain | typeof READINESS_DOMAIN | 'critical' | 'subdomainScores',
    { value: number; type?: THealthDomainQuestionType } | any
  >,
): TScreenerFormForBE => {
  const scoresPerDomain: Record<THealthDomain, number> = {
    physical: formValues.physical.value,
    mental: formValues.mental.value,
    social: formValues.social.value,
    vocational: formValues.vocational.value,
    spiritual: formValues.spiritual.value,
    purpose: formValues.purpose.value,
    fun: formValues.fun.value,
  };

  const { readiness, ...subdomainScores } = formValues.subdomainScores;

  const subdomainScoresForBE = {
    physical: mapSubdomainScoreToBE(subdomainScores.physical),
    mental: mapSubdomainScoreToBE(subdomainScores.mental),
    social: mapSubdomainScoreToBE(subdomainScores.social),
    vocational: mapSubdomainScoreToBE(subdomainScores.vocational),
    spiritual: mapSubdomainScoreToBE(subdomainScores.spiritual),
    purpose: mapSubdomainScoreToBE(subdomainScores.purpose),
    fun: mapSubdomainScoreToBE(subdomainScores.fun),
  };

  const readinessForBE = {
    ...mapSubdomainScoreToBE(readiness),
    value: formValues.readiness.value,
  };

  const criticalForBE = formValues.critical;

  const domainQuestionsWithValues: TDomainQuestionsWithValues = {
    physical: { questions: [formValues.physical] },
    mental: { questions: [formValues.mental] },
    social: { questions: [formValues.social] },
    vocational: { questions: [formValues.vocational] },
    spiritual: { questions: [formValues.spiritual] },
    purpose: { questions: [formValues.purpose] },
    fun: { questions: [formValues.fun] },
  };

  return {
    scoresPerDomain,
    subdomainScores: subdomainScoresForBE,
    readiness: readinessForBE,
    critical: criticalForBE,
    domainQuestionsWithValues,
  };
};
