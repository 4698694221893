const rootPath = '/img/png/icons';

const iconsPng = {
  eyeClosed: `${rootPath}/eye-closed.png`,
  eyeOpened: `${rootPath}/eye-opened.png`,
  lock: `${rootPath}/lock.png`,
  info: `${rootPath}/info.png`,
  redX: `${rootPath}/red-x.png`,
  addMember: `${rootPath}/add-member.png`,
  lockPassword: `${rootPath}/lock-password.png`,
  userDeactivate: `${rootPath}/user-deactivate.png`,
  okHand: `${rootPath}/ok-hand.png`,
  notepad: `${rootPath}/notepad.png`,
  pen: `${rootPath}/pen.png`,
  sliderCircleGrey: `${rootPath}/slider-circle-circled.png`,
  sliderCircleUntouched: `${rootPath}/slider-circle-untouched.png`,
  orangeExclamationMark: `${rootPath}/orange-exclamation.png`,
  topRightBackground: `${rootPath}/top-right-background-circle.png`,
  personReading: `${rootPath}/person-reading.png`,
  handshake: `${rootPath}/handshake.png`,
  heartInHand: `${rootPath}/heart-in-hand.png`,
  whiteExclamationOnPurpleTriangle: `${rootPath}/white-exclamation-on-purple-triangle.png`,
  wellnessDomainsGray: {
    fun: `${rootPath}/wellness-domains-gray/fun.png`,
    vocational: `${rootPath}/wellness-domains-gray/vocational.png`,
    spiritual: `${rootPath}/wellness-domains-gray/spiritual.png`,
    mental: `${rootPath}/wellness-domains-gray/mental.png`,
    physical: `${rootPath}/wellness-domains-gray/physical.png`,
    purpose: `${rootPath}/wellness-domains-gray/purpose.png`,
    social: `${rootPath}/wellness-domains-gray/social.png`,
  },
  wellnessDomainsPurple: {
    fun: `${rootPath}/wellness-domains-purple/fun.png`,
    vocational: `${rootPath}/wellness-domains-purple/vocational.png`,
    spiritual: `${rootPath}/wellness-domains-purple/spiritual.png`,
    mental: `${rootPath}/wellness-domains-purple/mental.png`,
    physical: `${rootPath}/wellness-domains-purple/physical.png`,
    purpose: `${rootPath}/wellness-domains-purple/purpose.png`,
    social: `${rootPath}/wellness-domains-purple/social.png`,
    readiness: `${rootPath}/wellness-domains-purple/readiness.png`,
  },
};

export default iconsPng;
