import { TTherapistApi } from "@/types/Therapist.types";
import { api, TCustomAxiosResponse } from "..";
import { INFINITE_SCROLL_CARDS_PER_PAGE } from "@/constants";

const root = 'v1/therapists';

const endpoints = {
  therapists: root,
};

export const getTherapists = async (
  page: number,
    limit = INFINITE_SCROLL_CARDS_PER_PAGE,
): Promise<TCustomAxiosResponse<TTherapistApi[]>> => {

  const { data } = await api.get<TCustomAxiosResponse<TTherapistApi[]>>(endpoints.therapists, { params: { page, limit } });

  return data;
};

export const getTherapistById = async (id: string): Promise<TTherapistApi> => {
  const { data } = await api.get(`${endpoints.therapists}/${id}`);

  const therapist = data.data as TTherapistApi;

  return therapist;
};

export const postTherapyRequest = async (therapistId: string): Promise<TCustomAxiosResponse<unknown>> => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(`v1/therapist/request`, { therapistId});

  return data;
}