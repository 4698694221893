import React from 'react';
import DirectoryCardBase from './DirectoryCardBase';
import { TPeerSupportTeamMemberInfo } from '@/types/PeerSupport.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';

type Props = {
  member: TPeerSupportTeamMemberInfo;
};

const NAME_LENGTH_TO_PUSH_ABOUT_ME = 25;
const ACTION_BUTTON_WIDTH = '170px';

const PeerSupportDirectoryCard = ({ member }: Props) => {
  return (
    <DirectoryCardBase
      name={member.name}
      pictureUrl={member.profileImage}
      pictureLink={'/directory/peer-support/$peerSupportId'}
      routeParams={{ peerSupportId: member.id }}
    >
      <HStack width={'100%'} justifyContent={'space-between'} marginTop={'10px'}>
        <VStack gap={'4px'} align={'start'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            PEER’S AGE
          </Text>
          <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
            {member.yearsOfAge}
          </Text>
        </VStack>
        <VStack gap={'4px'} align={'start'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            PHONE NUMBER
          </Text>
          <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
            {member.phone ?? '/'}
          </Text>
        </VStack>
      </HStack>
      <VStack width={'100%'} height={'100%'} justify={'space-between'}>
        {/* About Me, ex Bio */}
        <VStack marginTop={'10px'} align={'start'} gap={0} width={'100%'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            ABOUT ME
          </Text>
          <Text
            height={'100%'}
            width={'100%'}
            whiteSpace={'pre-wrap'}
            variant={'urbanistSemiBoldRegular'}
            color={'text.mediumBlue'}
            noOfLines={member.name.length > NAME_LENGTH_TO_PUSH_ABOUT_ME ? 7 : 8}
          >
            {member.bio ?? 'No About Me'}
          </Text>
        </VStack>

        {/* Action Buttons */}
        <HStack width={'100%'} justifyContent={'center'}>
          <SendEmailModalWithTrigger
            peerSupportOrMemberCare={'peer-support'}
            recipientId={member.id}
            toEmailName={member.name}
            triggerButtonWidth={ACTION_BUTTON_WIDTH}
            backgroundColor={colors.extra.white}
            noShadow
          />
        </HStack>
      </VStack>
    </DirectoryCardBase>
  );
};

export default PeerSupportDirectoryCard;
