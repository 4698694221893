import { OPTION_OTHER } from '@/utils/demographicDataMappers';
import RadioButton, { TRadioButtonFontSize } from './RadioButton';
import { Flex } from '@chakra-ui/react';
import { CSSProperties } from 'react';

const RadioButtonGroup = ({
  items,
  selectedItem,
  onClick,
  textInputValue,
  setTextInputValue,
  containerStyle,
  noOfLines = 1,
  fontSize = 'sm',
  vertical = false,
  borderWidth = 2,
}: {
  items: string[];
  selectedItem: string;
  onClick: (_: string) => void;
  textInputValue?: string;
  setTextInputValue?: (_: string) => void;
  containerStyle?: CSSProperties;
  noOfLines?: number;
  fontSize?: TRadioButtonFontSize;
  vertical?: boolean;
  borderWidth?: number;
}) => {
  return (
    <Flex gap={5} flexWrap={'wrap'} flexDirection={vertical ? 'column' : 'row'} style={containerStyle}>
      {items.map((item) => (
        <RadioButton
          key={item}
          label={item}
          isSelected={selectedItem === item}
          hasTextInput={item === OPTION_OTHER && selectedItem === item}
          textInputValue={textInputValue}
          setTextInputValue={setTextInputValue}
          onClick={() => onClick(item)}
          fontSize={fontSize}
          noOfLines={noOfLines}
          borderWidth={borderWidth}
        />
      ))}
    </Flex>
  );
};

export default RadioButtonGroup;
