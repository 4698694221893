import {
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import svgIcons from '@/assets/svg/icons';
import SvgIcon from '../SvgIcon';
import { useMemo } from 'react';

type TSection<T extends string> = Record<T, readonly string[]>;

interface Props<TSectionName extends string> {
  disclosure: UseDisclosureReturn;
  title: string;
  sections: TSection<TSectionName>;
  selectedSectionsOptions?: Partial<TSection<TSectionName>>;
  sectionLabelDisplay?: (_: TSectionName) => JSX.Element;
  sectionOptionLabelDisplay?: (_: string) => string;
  onSectionOptionClick: (section: string, selectedSectionOption: string) => void;
  height?: string;
  popoverMaxHeight?: number;
  width?: number;
  popoverWidth?: number;
  noOptionsTooltip?: string;
  onBottomCloseButtonClick?: () => void;
}

const DropdownWithSections = <TSectionName extends string>({
  disclosure,
  title,
  sections,
  selectedSectionsOptions,
  onSectionOptionClick,
  sectionLabelDisplay,
  sectionOptionLabelDisplay,
  height = '54px',
  popoverMaxHeight = 300,
  width = 300,
  popoverWidth = width,
  noOptionsTooltip = 'No options available',
  onBottomCloseButtonClick,
}: Props<TSectionName>) => {
  const { isOpen, onOpen, onClose } = disclosure;

  const hasNoOptions = Object.values(sections).every((options) => (options as string[]).length === 0);

  const selectedSectionsOptionsWithData = selectedSectionsOptions
    ? Object.fromEntries(
        Object.entries(selectedSectionsOptions).filter(([key]) => selectedSectionsOptions[key as TSectionName]?.length),
      )
    : undefined;

  const noValueSelected =
    !selectedSectionsOptionsWithData ||
    Object.entries(selectedSectionsOptionsWithData).every(
      ([_, sectionItems]) => (sectionItems as string[]).length === 0,
    );

  const notAllSectionsSelected =
    selectedSectionsOptionsWithData &&
    Object.entries(selectedSectionsOptionsWithData).length !== Object.entries(sections).length;

  const selectedOptionsLabel = useMemo(() => {
    if (selectedSectionsOptionsWithData) {
      return Object.values(selectedSectionsOptionsWithData)
        .map((values) =>
          (values as string[]).map((value) => sectionOptionLabelDisplay?.(value as TSectionName)).join(', '),
        )
        .join(', ');
    }
  }, [selectedSectionsOptionsWithData]);

  return (
    <VStack>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
        <PopoverTrigger>
          <Button
            minHeight={'40px'}
            height={height}
            width={width}
            bg={isOpen ? colors.background.lightBlue : 'transparent'}
            cursor={'pointer'}
            padding={0}
            border={`1px solid ${noValueSelected || notAllSectionsSelected ? '#E2E8F0' : '#004179'}`}
            boxShadow={`0px 2px ${noValueSelected || notAllSectionsSelected ? '4px 0px #00417911' : '8px 0px #00417933'}`}
            backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
            _hover={{ backgroundColor: colors.background.lightBlue }}
            isDisabled={hasNoOptions}
            position={'relative'}
          >
            <Tooltip
              visibility={hasNoOptions ? 'visible' : 'hidden'}
              label={noOptionsTooltip}
              minWidth={'330px'}
              padding={'14px'}
              borderRadius={'6px'}
              color={'text.darkBlue'}
              backgroundColor={'extra.white'}
              boxShadow={'0px 2px 8px 0px #00417933'}
            >
              <HStack height={'100%'} width={'100%'} padding={'18px'}>
                <VStack flexGrow={1} alignItems={'flex-start'}>
                  {noValueSelected ? (
                    <Text
                      fontSize={'14px'}
                      color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                      lineHeight={'20px'}
                      fontWeight={700}
                      maxWidth={`${width ? width - 50 : 100}px`}
                      isTruncated
                    >
                      {title}
                    </Text>
                  ) : (
                    <VStack alignItems={'flex-start'} gap={'2px'}>
                      <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                        {title.toUpperCase()}
                      </Text>
                      <Text variant={'urbanistSemiBoldRegular'} maxWidth={`${width ? width - 70 : 100}px`} isTruncated>
                        {selectedOptionsLabel}
                      </Text>
                    </VStack>
                  )}
                </VStack>
                {isOpen ? (
                  <ChevronUpIcon
                    boxSize={'5'}
                    color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue}
                  />
                ) : (
                  <ChevronDownIcon
                    boxSize={'5'}
                    color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue}
                  />
                )}
              </HStack>
            </Tooltip>
            {notAllSectionsSelected && (
              <Text position={'absolute'} bottom={'-5px'} variant={'error'} backgroundColor={'green'} maxHeight={0}>
                Please select at least one option in each section
              </Text>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          bg="white"
          width={`${popoverWidth}px`}
          maxHeight={`${popoverMaxHeight}px`}
          overflowY={'scroll'}
          border={'1px solid #95B1C9'}
          padding={'20px'}
          paddingBottom={'15px'}
          borderRadius={'8px'}
          boxShadow={'0px 6px 12px 0px #0E006224'}
        >
          {Object.entries(sections).map(([sectionName, subdomains], i) => (
            <VStack marginTop={i > 0 ? '18px' : 0} key={sectionName}>
              {sectionLabelDisplay ? sectionLabelDisplay(sectionName as TSectionName) : sectionName}

              {(subdomains as string[]).map((subdomain) => {
                const isOptionSelected = selectedSectionsOptions?.[sectionName as TSectionName]?.includes(subdomain);
                return (
                  <HStack
                    key={subdomain as string}
                    cursor={'pointer'}
                    justifyContent={'space-between'}
                    onClick={() => onSectionOptionClick(sectionName, subdomain)}
                    width={'100%'}
                    data-group
                  >
                    <Text fontWeight={600} _groupHover={{ fontWeight: 700 }} noOfLines={1} color={'text.darkBlue'}>
                      {sectionOptionLabelDisplay ? sectionOptionLabelDisplay(subdomain) : subdomain}
                    </Text>
                    {isOptionSelected && (
                      <SvgIcon color={colors.primary[500]} iconPath={svgIcons.checkWhite} size={'12'} />
                    )}
                  </HStack>
                );
              })}
            </VStack>
          ))}

          {onBottomCloseButtonClick && (
            <VStack padding={'4px'} paddingTop={'8px'}>
              <Tooltip
                visibility={noValueSelected || notAllSectionsSelected ? 'visible' : 'hidden'}
                label={'Please select at least one option in each section'}
                minWidth={'330px'}
                padding={'10px'}
                borderRadius={'6px'}
                color={'text.darkBlue'}
                backgroundColor={'extra.white'}
                boxShadow={'0px 2px 8px 0px #00417933'}
                placement="right"
              >
                <VStack
                  onClick={() => {
                    if (noValueSelected || notAllSectionsSelected) {
                    } else {
                      onClose();
                      onBottomCloseButtonClick();
                    }
                  }}
                  borderRadius={'50%'}
                  cursor={noValueSelected || notAllSectionsSelected ? 'not-allowed' : 'pointer'}
                  paddingX={'18px'}
                >
                  <SvgIcon
                    iconPath={svgIcons.circledArrowRight}
                    color={colors.extra.teal}
                    additionalSvgProps={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                    size={23}
                  />
                </VStack>
              </Tooltip>
            </VStack>
          )}
        </PopoverContent>
      </Popover>
    </VStack>
  );
};

export default DropdownWithSections;
