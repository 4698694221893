import { TUserSendEmailData, TUserSendEmailPeerSupportOrMemberCare } from '@/types/User.types';
import { api } from '..';

const rootPath = '/v1/contact';

const endpoints = {
  contact: rootPath,
};

export const sendEmail = async (
  peerSupportOrMemberCare: TUserSendEmailPeerSupportOrMemberCare,
  userSendEmailData: TUserSendEmailData,
): Promise<{ success: boolean }> => {
  const { data } = await api.post(`${endpoints.contact}/${peerSupportOrMemberCare}`, userSendEmailData);
  return data.data;
};
