import { TMemberCare } from '@/types/MemberCare.types';
import React from 'react';
import DirectoryCardBase from './DirectoryCardBase';
import { getMemberCareRoleFromName } from '@/utils/stringUtils';
import { HStack, Text, VStack } from '@chakra-ui/react';
import Calendly from '@/components/molecules/Calendly';
import CustomButton from '@/components/atoms/CustomButton';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import colors from '@/theme/colors';
import { mixpanelEvents } from '@/constants/mixpanel';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';

type Props = {
  member: TMemberCare;
};

const ACTION_BUTTON_WIDTH = '120px';

const MemberCareDirectoryCard = ({ member }: Props) => {
  const { trackEvent } = useTelemetry();
  const [isCalendlyOpen, setIsCalendlyOpen] = React.useState(false);

  const name = `${member.firstName} ${member.lastName}`;

  const role = getMemberCareRoleFromName(name);

  return (
    <DirectoryCardBase
      pictureUrl={member.profilePicture}
      pictureLink={'/directory/member-care/$memberCareId'}
      routeParams={{ memberCareId: member.user_id }}
      name={name}
      roleSubtitle={role}
    >
      <VStack flex={1} align={'start'} gap={'4px'} width={'100%'}>
        <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
          ABOUT ME
        </Text>
        <Text
          height={'100%'}
          width={'100%'}
          whiteSpace={'pre-wrap'}
          variant={'urbanistSemiBoldRegular'}
          color={'text.mediumBlue'}
          noOfLines={9}
        >
          {member.bio ?? 'No About Me'}
        </Text>
      </VStack>
      <HStack width={'100%'} justifyContent={'space-between'}>
        {member.calendly_link ? (
          <VStack gap={0}>
            <Calendly isOpen={isCalendlyOpen} setIsOpen={setIsCalendlyOpen} url={member.calendly_link} />
            <CustomButton
              isTransparent
              label="Schedule"
              hoverBackgroundColor="primary.500"
              labelColor="primary.500"
              borderHoverColor="primary.500"
              onClick={() => {
                setIsCalendlyOpen(true);
                trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
              }}
              width={ACTION_BUTTON_WIDTH}
              labelHoverColor="white"
              iconBeforeLabelJSX={<SvgIconCalendar color={colors.primary[500]} size={18} />}
              iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
              disabled={member?.calendly_link === ''}
            />
          </VStack>
        ) : (
          <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'} paddingX={'10px'}>
            No Calendly Link
          </Text>
        )}

        <SendEmailModalWithTrigger
          peerSupportOrMemberCare={'member-care'}
          recipientId={member.user_id}
          toEmailName={`${member.firstName} ${member.lastName}`}
          triggerButtonLabel="Email"
          triggerButtonWidth={ACTION_BUTTON_WIDTH}
          backgroundColor={colors.extra.white}
          noShadow
        />
      </HStack>
    </DirectoryCardBase>
  );
};

export default MemberCareDirectoryCard;
