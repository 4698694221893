import { useAppStore } from "@/store/useAppStore"
import { useInfinitePagination } from "../useInfinitePagination";
import { INFINITE_SCROLL_CARDS_PER_PAGE } from "@/constants";
import queryKeys from "@/constants/queryKeys";
import { getTherapists } from "@/services/api/requests/therapist";
import { TTherapistApi } from "@/types/Therapist.types";

const useTherapistsDirectory = () => {
  const { user } = useAppStore();

  return useInfinitePagination<TTherapistApi[]>({
    query: getTherapists,
    queryKey: [queryKeys.therapists.directory, user?.email ?? ''],
    limit: INFINITE_SCROLL_CARDS_PER_PAGE,
  });

}

export default useTherapistsDirectory;