import svgIcons from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { TSupportTeamMember } from '@/types/User.types';
import { formatKebabCaseString } from '@/utils/stringUtils';
import { Card, Center, HStack, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import MemberCareCardContent from './content/MemberCare';
import PeerSupportCardContent from './content/PeerSupport';
import TherapistCardContent from './content/Therapist';

const CARD_WIDTH = '380px';
const CARD_HEIGHT = '480px';

type Props = {
  member: TSupportTeamMember;
};

const SupportTeamMemberCard = ({ member }: Props) => {
  const navigate = useNavigate();

  const getCardContent = (role: 'member-care' | 'therapist' | 'peer-support'): React.ReactNode => {
    switch (role) {
      case 'member-care':
        return <MemberCareCardContent member={member} />;
      case 'peer-support':
        return <PeerSupportCardContent member={member} />;
      case 'therapist':
        return <TherapistCardContent member={member} />;
      default:
        break;
    }
  };

  return (
    <VStack key={member.id} width={'100%'} alignItems={'center'}>
      <Card
        size="sm"
        width={CARD_WIDTH}
        height={CARD_HEIGHT}
        variant={'elevated'}
        borderRadius={'8px'}
        padding={'20px'}
        shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
      >
        <Tooltip
          label={'Click to view profile'}
          padding={'14px'}
          borderRadius={'6px'}
          color={'text.darkBlue'}
          backgroundColor={'extra.white'}
          boxShadow={'0px 2px 8px 0px #00417933'}
          placement="right"
          right={55}
          top={-2}
        >
          <HStack
            width="100%"
            paddingBottom={'20px'}
            gap={'20px'}
            cursor={'pointer'}
            data-group
            onClick={() => {
              if (member.type === 'member-care') {
                navigate({
                  to: '/directory/member-care/$memberCareId',
                  params: { memberCareId: member.id },
                });
              }
              if (member.type === 'peer-support') {
                navigate({
                  to: '/directory/peer-support/$peerSupportId',
                  params: { peerSupportId: member.id },
                });
              }
              if (member.type === 'therapist') {
                navigate({
                  to: '/directory/therapist/$therapistId',
                  params: { therapistId: member.id },
                });
              }
            }}
          >
            {/* Support pic */}
            <VStack
              maxWidth={'100px'}
              maxHeight={'100px'}
              borderWidth={'4px'}
              borderRadius={'full'}
              borderColor={'white'}
              shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
              overflow={'hidden'}
              cursor={'pointer'}
              _groupHover={{ boxShadow: '0px 4px 8px 0px rgba(14, 0, 98, 0.2)' }}
              transition={'box-shadow 0.1s'}
            >
              {member.profileImage ? (
                <Image
                  src={member.profileImage}
                  alt={member.name}
                  width={100}
                  height={100}
                  borderRadius={'full'}
                  objectFit={'cover'}
                />
              ) : (
                <Center backgroundColor={'#EDE8FD'} height={100} width={100}>
                  <SvgIcon color={'#fff'} iconPath={svgIcons.userSeeThrough} size={60} boxHeight={80} boxWidth={80} />
                </Center>
              )}
            </VStack>

            {/* Support role and name */}
            <VStack align={'start'}>
              <Text variant={'urbanistBold'} color={'text.mediumGray'}>
                {formatKebabCaseString(member.type)}
              </Text>

              <Text
                variant={'lora'}
                fontSize={'22px'}
                lineHeight={'22px'}
                fontWeight={500}
                color={'text.darkBlue'}
                fontStyle={'italic'}
              >
                {member.name}
              </Text>
            </VStack>
          </HStack>
        </Tooltip>
        <VStack>{getCardContent(member.type)}</VStack>
      </Card>
    </VStack>
  );
};

export default SupportTeamMemberCard;
