/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldValues } from 'react-hook-form';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

type TScreenerState = {
  screenerId: string;
  slug: string;
  form: Record<string, any>;
  step: number;
  isCompleted: boolean;
  registerField: (key: string, healthDomain: string) => void;
  setScreenerId: (screenerId: string, slug: string) => void;
  // demographics
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  currentSlide: number;
  setCurrentSlide: (_: number) => void;
  currentDomainIndex: number;
  setCurrentDomainIndex: (_: number) => void;
  currentSubdomainsSlidePerDomain: Record<string, number>;
  setCurrentSubdomainsSlidePerDomain: (domain: string, slideNumber: number) => void;
  currentFormState: FieldValues;
  setCurrentFormState: (_: FieldValues) => void;
  timeStarted: Date | undefined;
  setTimeStarted: (_: Date) => void;
};

const initialState = {
  screenerId: '',
  slug: '',
  form: {},
  step: 0,
  isCompleted: false,
  demographics: null,
  currentSlide: 0,
  currentDomainIndex: 0,
  currentSubdomainsSlidePerDomain: {
    mental: 0,
    physical: 0,
    social: 0,
    spiritual: 0,
    vocational: 0,
    purpose: 0,
    fun: 0,
    readiness: 0,
  },
  currentFormState: {},
  timeStarted: undefined,
};

export const useScreenerStore = create<TScreenerState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setScreenerId: (screenerId: string, slug: string) => {
          set((state) => ({
            ...state,
            screenerId,
            slug,
          }));
        },
        registerField: (key: string, healthDomain: string) => {
          set((state) => ({
            ...state,
            form: {
              ...state.form,
              [key]: healthDomain,
            },
          }));
        },
        nextStep: () => {
          set((state) => {
            if (!state.isCompleted) {
              return { ...state, step: state.step + 1 };
            }
            return state;
          });
        },
        prevStep: () =>
          set((state) => {
            if (state.step === 0) {
              return state;
            }
            return { ...state, step: state.step - 1 };
          }),
        setCurrentSlide: (currentSlide: number) =>
          set((state) => {
            return { ...state, currentSlide };
          }),
        setCurrentSubdomainsSlidePerDomain: (domain: string, currentSubdomainsSlide: number) =>
          set((state) => {
            return {
              ...state,
              currentSubdomainsSlidePerDomain: {
                ...state.currentSubdomainsSlidePerDomain,
                [domain]: currentSubdomainsSlide,
              },
            };
          }),
        setCurrentDomainIndex: (currentDomainIndex: number) =>
          set((state) => {
            return { ...state, currentDomainIndex };
          }),
        setCurrentFormState: (currentFormState: FieldValues) =>
          set((state) => {
            return { ...state, currentFormState };
          }),
        setTimeStarted: (timeStarted: Date) =>
          set((state) => {
            return { ...state, timeStarted };
          }),
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
            screenerId: state.screenerId,
            slug: state.slug,
          }));
        },
      }),
      { name: 'screenerStore' },
    ),
  ),
);
