import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import Calendly from '@/components/molecules/Calendly';
import { mixpanelEvents } from '@/constants/mixpanel';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import colors from '@/theme/colors';
import { TSupportTeamMember } from '@/types/User.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React, { useState } from 'react';

type Props = {
  member: TSupportTeamMember;
};

const MemberCareCardContent = ({ member }: Props) => {
  const { trackEvent } = useTelemetry();

  const navigate = useNavigate();
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  return (
    <VStack width={'100%'} gap={'20px'}>
      <HStack gap={'20px'} width={'100%'}>
        <CustomButton
          isTransparent
          label="Schedule Call"
          backgroundColor="primary.500"
          labelColor="primary.500"
          onClick={() => {
            setIsCalendlyOpen(true);
            trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
          }}
          style={{ border: `2px solid ${colors.primary[500]}`, flex: 1 }}
          labelHoverColor="white"
          iconBeforeLabelJSX={<SvgIconCalendar color={colors.primary[500]} size={18} />}
          iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
          disabled={member?.calendlyLink === ''}
        />
        <CustomButton
          isTransparent
          label={'View More'}
          onClick={() => navigate({ to: `/directory/${member.type}` })}
          backgroundColor={colors.text.mediumBlue}
          labelColor={colors.text.mediumBlue}
          style={{ border: `2px solid ${colors.text.mediumBlue}`, flex: 1 }}
          hoverBackgroundColor={colors.text.mediumBlue}
          labelHoverColor={colors.extra.white}
          iconAfterLabelSvgPath={svgIcons.rightArrow}
          iconAfterLabelSvgColor={colors.text.mediumBlue}
          iconAfterLabelSvgSize={14}
        />
      </HStack>
      <VStack flex={1} alignItems={'flex-start'} width={'100%'} gap={'4px'}>
        <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
          BIO
        </Text>
        <Text
          height={'100%'}
          width={'100%'}
          whiteSpace={'pre-wrap'}
          variant={'urbanistSemiBoldRegular'}
          color={'text.mediumBlue'}
          noOfLines={14}
        >
          {member?.bio ?? 'No Bio'}
        </Text>
      </VStack>
      {member?.calendlyLink && (
        <Calendly isOpen={isCalendlyOpen} setIsOpen={setIsCalendlyOpen} url={member.calendlyLink} />
      )}
    </VStack>
  );
};

export default MemberCareCardContent;
