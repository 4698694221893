import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import { SCREENER_SUB_DOMAINS_DISPLAY_LABELS } from '@/constants';
import { MAX_SCREENER_SLIDE_WIDTH } from '@/constants/dimensions';
import { SUBDOMAIN_QUESTIONS, SUBDOMAIN_SCORES_FORM_KEY } from '@/constants/screenerQuestions';
import ControlledSliderExtended from '@/providers/DocumentUiProvider/components/SliderExtended/ControlledSliderExtended';
import { useScreenerStore } from '@/store/useScreenerStore';
import { THealthDomain, THealthSubDomain } from '@/types/Health.types';
import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  domain: string;
  subdomains: THealthSubDomain[];
  onFirstSlideGoBack: () => void;
  onLastSlideContinue: (listOfSubdomainsWithAnswers: any) => void;
}

const CurrentSubdomainsSlidesContainer = ({ domain, subdomains, onFirstSlideGoBack, onLastSlideContinue }: Props) => {
  const {
    currentFormState: currentFormStateFromStore,
    currentSubdomainsSlidePerDomain,
    setCurrentSubdomainsSlidePerDomain,
  } = useScreenerStore();

  const currentSubdomainSlide = currentSubdomainsSlidePerDomain[domain];

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getValues, setValue, formState } = methods;

  const { dirtyFields } = formState;

  useEffect(() => {
    const currentSubdomainAnswersFromStore = currentFormStateFromStore[SUBDOMAIN_SCORES_FORM_KEY]?.[domain];

    if (currentSubdomainAnswersFromStore) {
      Object.entries(currentSubdomainAnswersFromStore).forEach(([subdomain, valuesArray]) => {
        if ((valuesArray as any[]).length) {
          emblaApi?.scrollTo(currentSubdomainSlide, true);

          (valuesArray as any[]).forEach((value, i) => {
            setValue(`${subdomain}.${i}`, value, {
              shouldDirty: true,
              shouldTouch: true,
            });
          });
        }
      });
    }
  }, [domain, currentFormStateFromStore[SUBDOMAIN_SCORES_FORM_KEY]?.[domain]]);

  const subdomainQuestionsKey = subdomains[currentSubdomainSlide];

  const numberOfDirtyQuestions = dirtyFields[subdomains[currentSubdomainSlide]]
    ? dirtyFields[subdomains[currentSubdomainSlide]].filter((dirty: boolean) => dirty === true).length
    : 0;

  const continueDisabled = useMemo(() => {
    const numberOfSubdomainQuestions = SUBDOMAIN_QUESTIONS[domain]?.[subdomains[currentSubdomainSlide]]?.length;
    const allSlideQuestionsAnswered = numberOfSubdomainQuestions === numberOfDirtyQuestions;

    return !allSlideQuestionsAnswered; // || (currentSlideSubdomainsQuestions && currentSlideSubdomainsQuestions.length === 0)
  }, [domain, subdomains, currentSubdomainSlide, numberOfDirtyQuestions]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: 0,
    watchDrag: () => {}, // disables dragging
  });

  const goToNextSlide = useCallback(() => {
    emblaApi?.scrollNext();

    const newCurrentSubdomainSlide = currentSubdomainSlide + 1;
    setCurrentSubdomainsSlidePerDomain(domain, newCurrentSubdomainSlide);

    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
  }, [domain, currentSubdomainSlide, emblaApi]);

  // initial scroll slide fix
  useEffect(() => {
    if (emblaApi) {
      emblaApi?.scrollTo(currentSubdomainSlide, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  const onContinue = useCallback(() => {
    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      goToNextSlide();

      window.scrollTo({ top: 0, behavior: 'smooth' });

      return;
    }

    const formValues: Record<THealthSubDomain, any[]> = getValues();
    const filterValuesOfCurrentSubdomains = Object.fromEntries(
      Object.entries(formValues).filter((entry) => subdomains.includes(entry[0])),
    );
    onLastSlideContinue(filterValuesOfCurrentSubdomains);
  }, [emblaApi, domain, subdomains, currentSubdomainSlide, onLastSlideContinue]);

  const goToPreviousSlide = () => {
    setCurrentSubdomainsSlidePerDomain(domain, currentSubdomainSlide - 1);
    emblaApi?.scrollPrev();

    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
  };

  const onGoBack = useCallback(() => {
    const canGoBack = emblaApi?.canScrollPrev();

    if (canGoBack) {
      goToPreviousSlide();
    } else {
      onFirstSlideGoBack();
    }
  }, [goToPreviousSlide, onFirstSlideGoBack]);

  const subdomainScreenerSlides = useMemo(() => {
    return subdomains.map((subdomain) => (
      <VStack className="embla__slide" key={subdomain}>
        <VStack paddingX={'10px'} paddingBottom={'40px'} gap={0}>
          <HStack gap={'10px'}>
            <VStack padding={'6px'} backgroundColor={'background.lightPurple'} borderRadius={'4px'}>
              <Image src={iconsPng.wellnessDomainsPurple[domain as THealthDomain]} height={'16px'} width={'16px'} />
            </VStack>
            <Text variant="urbanistSemiBoldLarge" color={'primary.500'}>
              {SCREENER_SUB_DOMAINS_DISPLAY_LABELS[subdomain]}
            </Text>
          </HStack>
          <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'} marginTop={'40px'}>
            Please answer additional questions below.
          </Text>
          {SUBDOMAIN_QUESTIONS[domain][subdomain]?.map((subdomainQuestion, i) => (
            <VStack width={MAX_SCREENER_SLIDE_WIDTH} key={subdomainQuestion.text} gap={'40px'} marginY={'40px'}>
              <Text variant={'loraMedium'} fontWeight={400} color={'text.mediumBlue'}>
                {subdomainQuestion.text}
              </Text>
              <ControlledSliderExtended
                name={`${subdomainQuestionsKey}.${i}`}
                healthDomain={domain}
                question={subdomainQuestion.text}
                inputId={subdomainQuestion.inputId}
                labelStart={subdomainQuestion.textMin}
                labelEnd={subdomainQuestion.textMax}
              />
            </VStack>
          ))}
        </VStack>

        {/* Continue/Finish and Go Back buttons */}
        <VStack marginTop={'20px'} marginBottom={'40px'} width={'100%'}>
          <Button
            variant={'continue'}
            isDisabled={continueDisabled}
            _hover={{
              backgroundColor: continueDisabled ? 'primary.400' : 'primary.600',
            }}
            onClick={onContinue}
          >
            Continue
          </Button>

          <CustomButton
            isTransparent
            labelHoverColor="primary.black"
            label="Go Back"
            borderHoverColor="transparent"
            onClick={onGoBack}
          />
        </VStack>
      </VStack>
    ));
  }, [subdomains, domain, subdomainQuestionsKey, continueDisabled, onContinue, onGoBack]);

  return (
    <VStack width={'100vw'}>
      <FormProvider {...methods}>
        <VStack width={'100vw'}>
          <section className="embla" key={subdomains.toString()} style={{ width: '100%' }}>
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">{subdomainScreenerSlides}</div>
            </div>
          </section>
        </VStack>
      </FormProvider>
    </VStack>
  );
};

export default CurrentSubdomainsSlidesContainer;
