import queryKeys from '@/constants/queryKeys';
import { getMemberCareFromMyOrgById } from '@/services/api/requests/organization';
import { useQuery } from '@tanstack/react-query';

const useMemberCareById = (memberCareId: string) => {
  const { isLoading, data, isSuccess } = useQuery({
    queryKey: [queryKeys.memberCare.memberCareById, memberCareId],
    queryFn: () => getMemberCareFromMyOrgById(memberCareId),
  });

  return { isLoading, data, isSuccess };
};

export default useMemberCareById;
