import useTherapistById from '@/hooks/therapist/useTherapistById';
import DirectoryProfileViewPageTemplate from '@/modules/directory/DirectoryProfileViewPageTemplate';
import RequestATherapySessionModalTrigger from '@/modules/user/RequestATherapySessionModalTrigger';
import colors from '@/theme/colors';
import { displayCapitalizedUnderscoredString } from '@/utils/stringUtils';
import { Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';

const TherapistProfileView = () => {
  const { therapistId } = Route.useParams();

  const { data: therapist, isLoading } = useTherapistById(therapistId);

  return (
    <DirectoryProfileViewPageTemplate
      isLoading={isLoading}
      name={`${therapist?.first_name} ${therapist?.last_name}`}
      profileImage={therapist?.profile_photo}
      role={'Therapist'}
      description={therapist?.appointment_types.map((d) => displayCapitalizedUnderscoredString(d)).join(', ')}
    >
      {therapist && (
        <Text
          height={'100%'}
          width={'100%'}
          whiteSpace={'pre-wrap'}
          variant={'urbanistSemiBoldRegular'}
          color={'text.mediumBlue'}
          noOfLines={5}
          textAlign={'center'}
        >
          {therapist.designation.map((d) => displayCapitalizedUnderscoredString(d)).join(', ')}
        </Text>
      )}
      {therapist && (
        <RequestATherapySessionModalTrigger
          triggerButtonTransparent
          triggerButtonWidth={'170px'}
          backgroundColor={colors.extra.lightBlue}
          noShadow
          hoveredBackgroundColor={'transparent'}
          hoveredLabelColor="primary.500"
          therapist={{
            name: `${therapist?.first_name} ${therapist?.last_name}`,
            id: therapist._id,
            designation: therapist.designation,
            profilePhoto: therapist.profile_photo,
          }}
        />
      )}
      {therapist && (
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
          boxShadow={'0px 0px 20px 0px #00417926'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            About Me
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {therapist.bio ?? 'No Bio'}
          </Text>
        </VStack>
      )}
    </DirectoryProfileViewPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_therapist/directory/therapist/$therapistId')({
  component: TherapistProfileView,
});
