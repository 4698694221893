const queryKeys = {
  auth: { user: 'user-auth' },
  user: {
    dashboard: 'user-dashboard',
  },
  organization: {
    members: 'org-members',
    dashboard: 'org-dashboard',
    events: {
      outreach: 'org-event-outreach',
    },
  },
  peerSupport: {
    dashboard: 'peer-support-dashboard',
    members: 'peer-support-members',
  },
  therapists: {
    directory: 'therapists-directory',
    byId: 'therapist-by-id',
  },
  legal: {
    privacyPolicy: 'legal-privacy-policy',
    termsOfUse: 'legal-terms-of-use',
  },
  documents: {
    screener: 'screener-documents',
  },
  memberCare: {
    myOrganizationMemberCares: 'member-care-my-organization-member-cares',
    memberCareById: 'member-care-by-id',
  },
  notes: {
    self: 'notes-self',
  },
  timeline: {
    self: 'timeline-self',
  },
};

export default queryKeys;
