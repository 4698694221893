import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { createFileRoute, Outlet } from '@tanstack/react-router';

const DirectoryLayout = () => {
  return (
    <AppPageTemplate>
      <Outlet />
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory')({
  component: DirectoryLayout,
});
