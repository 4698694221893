import { Card, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CustomButton from '@/components/atoms/CustomButton';
import iconsPng from '@/assets/img/png/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import svgIcons from '@/assets/svg/icons';
import { useNavigate } from '@tanstack/react-router';
import sharedRoutes from '@/constants/routes/shared';
import { TSupportTeamMember } from '@/types/User.types';
import SupportTeamMemberCard from './supportTeam/SupportTeamMemberCard';

const CARD_HEIGHT = '480px';

interface Props {
  data: TSupportTeamMember[];
  isLoading?: boolean;
}

const UserSupportTeamSection = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <></>;
  }

  if (!data || data.length === 0) {
    return (
      <VStack width={'100%'} height={CARD_HEIGHT} justify={'center'} alignItems={'center'}>
        <Card
          size="sm"
          minWidth={'370px'}
          minHeight={'215px'}
          variant={'elevated'}
          borderRadius={'20px'}
          padding={'30px'}
          shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <VStack position={'absolute'} top={-50}>
            <Image alt="notepad" src={iconsPng.notepad} width={100} height={100} />
          </VStack>
          <Text
            variant={'lora'}
            fontSize={'22px'}
            lineHeight={'22px'}
            fontWeight={500}
            color={'text.mediumBlue'}
            fontStyle={'italic'}
            marginTop={'50px'}
          >
            No support team members assigned?
          </Text>

          <CustomButton
            label="Contact us"
            style={{ marginTop: '40px' }}
            backgroundColor="primary.500"
            labelColor="extra.white"
            iconBeforeLabelJSX={<SvgIcon iconPath={svgIcons.email} size={19} color={'white'} />}
            onClick={() => {
              navigate({ to: sharedRoutes.contactSupport });
            }}
          />
        </Card>
      </VStack>
    );
  }

  return (
    <HStack width={'100%'} minH={'500px'} gap={'40px'}>
      {data.map((supportTeamMember) => (
        <SupportTeamMemberCard key={supportTeamMember.id} member={supportTeamMember} />
      ))}
    </HStack>
  );
};

export default UserSupportTeamSection;
