import { postTherapyRequest } from "@/services/api/requests/therapist";
import { useMutation } from "@tanstack/react-query";

const useTherapyRequest = (therapistId: string, onSuccessCallback: () => void) => {
  const { isSuccess, isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await postTherapyRequest(therapistId);
      return response;
    },
    onSuccess: () => {
      if(onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: () => {

    }
  })

  return { isPending, isSuccess, mutate };
}

export default useTherapyRequest;