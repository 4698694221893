import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import colors from '@/theme/colors';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  isVisible: boolean;
  setIsVisible: (_: boolean) => void;
}

const SuicideInformationModal = ({ isVisible, setIsVisible }: Props) => {
  return (
    <ModalContainer
      isOpen={isVisible}
      closeOnOverlayClick={false}
      onClose={() => setIsVisible(false)}
      isBlur={false}
      width={'620px'}
      height={'750px'}
      backgroundColor={'background.lightBlue'}
      closeButtonHidden
      footerContent={
        <HStack justifyContent={'center'} width={'100%'} height={'100%'} marginBottom={'25px'}>
          <CustomButton
            label="Okay"
            backgroundColor="secondary.500"
            labelColor="extra.white"
            labelHoverColor={colors.extra.white}
            onClick={() => setIsVisible(false)}
            height={'54px'}
            width={'200px'}
          />
        </HStack>
      }
    >
      <Text variant={'loraMedium'} textAlign={'center'} fontSize={'22px'} marginTop={'20px'} color={'text.darkBlue'}>
        Your life has value.
      </Text>

      <VStack marginY={'40px'}>
        <Image src={iconsPng.heartInHand} width={'100px'} height={'100px'} />
      </VStack>

      <VStack gap={'30px'} alignItems={'center'} paddingX={'50px'}>
        <Text variant={'urbanistBoldLarge'} color={'text.mediumBlue'} width={'100%'}>
          Your life has value, and there is always hope.
        </Text>

        <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'}>
          For immediate crisis assistance, please call{' '}
          <a href={'tel:988'} style={{ color: colors.primary[500], fontWeight: 700 }}>
            988
          </a>{' '}
          or{' '}
          <a href={'tel:911'} style={{ color: colors.primary[500], fontWeight: 700 }}>
            911
          </a>
          .
        </Text>

        <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'}>
          MyOmnia's Member Care team is here to help you. Contact us for support or getting connected to the right
          resource.
        </Text>

        <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
          Call{' '}
          <a href={'tel:+1-385-298-8152'} style={{ color: colors.primary[500], fontWeight: 700 }}>
            385-298-8152
          </a>{' '}
          or {'\n '}Email{' '}
          <a
            href={'mailto:membercare@myomnia.health?subject=Request for Support'}
            target="_blank"
            style={{ color: colors.primary[500], textDecoration: 'underline', fontWeight: 700 }}
          >
            membercare@myomnia.health
          </a>
          {'\n '}
          to discuss support options.
        </Text>

        <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
          Business hours are (8 AM - 5 PM MT).
        </Text>

        <Text variant={'urbanistSemiBoldLarge'} fontWeight={500} color={'text.mediumBlue'}>
          If you are in need of support after hours, and do not want to call 988 or 911, reach out to your{' '}
          <a href="/peer-support/directory" style={{ color: colors.primary[500], fontWeight: 700 }}>
            Peer Support Team
          </a>
          .
        </Text>
      </VStack>
    </ModalContainer>
  );
};

export default SuicideInformationModal;
