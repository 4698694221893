import svgFiles from '@/assets/svg/svgFiles';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Image, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  isLoading?: boolean;
  name?: string;
  profileImage?: string;
  role?: string;
  description?: string;
};

const DirectoryProfileViewPageTemplate = ({
  isLoading,
  children,
  profileImage,
  name,
  role,
  description,
}: PropsWithChildren<Props>) => {
  if (isLoading) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH} marginBottom={'50px'}>
      <VStack
        borderWidth={'4px'}
        borderRadius={'full'}
        borderColor={'white'}
        shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
        overflow={'hidden'}
      >
        <Image
          src={profileImage ?? svgFiles.genericUser}
          alt={name}
          width={120}
          height={120}
          borderRadius={'full'}
          objectFit={'cover'}
          style={{ backgroundColor: 'white' }}
        />
      </VStack>
      <Text variant={'loraSmallTitle'} marginTop={'10px'}>
        {name}
      </Text>
      <Text variant={'urbanistSemiBold'}>{role}</Text>
      {description && (
        <Text variant={'urbanistRegular'} color={'text.mediumGray'}>
          {description}
        </Text>
      )}
      {children}
    </VStack>
  );
};

export default DirectoryProfileViewPageTemplate;
