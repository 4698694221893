import logoVariants from '@/assets/img/logo';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  isLoading?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onViewMoreClick?: () => void;
};

const DirectoryPageTemplate = ({
  title,
  children,
  isLoading = false,
  isFetchingNextPage = false,
  hasNextPage = false,
  onViewMoreClick = () => {},
}: PropsWithChildren<Props>) => {
  return (
    <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'40px'}>
      <Text variant={'loraTitle'}>{title}</Text>
      {isLoading && (
        <Flex
          width="100vw"
          height="100vh"
          flexDirection="column"
          alignItems={'center'}
          justifyContent={'center'}
          top={0}
          left={0}
          pos={'absolute'}
        >
          <LoadingLogo />
        </Flex>
      )}
      {!isLoading && (
        <VStack width={'100%'}>
          <HStack gap={'20px'} width={'100%'} wrap={'wrap'} marginTop={'110px'} rowGap={'80px'} justify={'center'}>
            {children}
          </HStack>
          {hasNextPage && !isFetchingNextPage && (
            <HStack cursor={'pointer'} onClick={onViewMoreClick} marginY={'30px'}>
              <Text
                variant={'urbanistSemiBoldSmallTitle'}
                cursor={'pointer'}
                _hover={{ fontWeight: 700 }}
                color={'primary.500'}
                fontSize="18px"
              >
                View More
              </Text>
            </HStack>
          )}

          {isFetchingNextPage && (
            <HStack paddingY={'20px'}>
              <LoadingLogo logoVariant={logoVariants.purpleNoText160x160} logoWidth="54px" logoHeight="54px" />
            </HStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default DirectoryPageTemplate;
