import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import usePeerSupportById from '@/hooks/peersupport/usePeerSupportFromMyOrgById';
import DirectoryProfileViewPageTemplate from '@/modules/directory/DirectoryProfileViewPageTemplate';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';

const PeerSupportProfileView = () => {
  const { peerSupportId } = Route.useParams();

  const { data: peerSupport, isLoading } = usePeerSupportById(peerSupportId);

  const phoneLinkRef = useRef<HTMLAnchorElement>(null);

  return (
    <DirectoryProfileViewPageTemplate
      isLoading={isLoading}
      name={`${peerSupport?.name}`}
      profileImage={peerSupport?.profileImage}
      role={'Peer Support Specialist'}
      description={`${peerSupport?.yearsOfAge} years old`}
    >
      {peerSupport && (
        <HStack gap={8} marginTop={'40px'}>
          <SendEmailModalWithTrigger
            peerSupportOrMemberCare={'peer-support'}
            recipientId={peerSupport.id}
            toEmailName={peerSupport.name}
            triggerButtonWidth="170px"
            backgroundColor={colors.extra.white}
            labelColor={colors.text.mediumBlue}
            triggerButtonTransparent={false}
            hoveredBackgroundColor={colors.extra.white}
            hoveredLabelColor={colors.extra.black}
          />
          <a
            ref={phoneLinkRef}
            href={`tel:+${peerSupport.phone?.includes('+') ? peerSupport.phone.substring(1) : peerSupport.phone}`}
            style={{ display: 'none' }}
          >
            {peerSupport.phone}
          </a>

          <CustomButton
            label="Phone call"
            backgroundColor="primary.500"
            labelColor="extra.white"
            onClick={() => phoneLinkRef.current?.click()}
            width={'170px'}
            style={{ border: `2px solid ${colors.primary[500]}` }}
            labelHoverColor="white"
            iconBeforeLabelJSX={<SvgIcon iconPath={svgIcons.phone} color={colors.extra.white} size={18} />}
          />
        </HStack>
      )}
      {peerSupport && (
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
          boxShadow={'0px 0px 20px 0px #00417926'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            About Me
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {peerSupport.bio ?? 'No Bio'}
          </Text>
        </VStack>
      )}
    </DirectoryProfileViewPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_peer-support/directory/peer-support/$peerSupportId')({
  component: PeerSupportProfileView,
});
