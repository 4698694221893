import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { TSupportTeamMember } from '@/types/User.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import SendEmailModalWithTrigger from '../../SendEmailModalWithTrigger';
import { useNavigate } from '@tanstack/react-router';

type Props = {
  member: TSupportTeamMember;
};

const PeerSupportCardContent = ({ member }: Props) => {
  const navigate = useNavigate();

  return (
    <VStack width={'100%'} gap={'20px'}>
      <HStack gap={'20px'} width={'100%'}>
        <SendEmailModalWithTrigger
          peerSupportOrMemberCare={'peer-support'}
          recipientId={member.id}
          toEmailName={member.name}
          triggerButtonLabel="Email"
          triggerButtonWidth={'100%'}
          triggerButtonTransparent={true}
        />
        <CustomButton
          isTransparent
          label={'More Peers'}
          onClick={() => navigate({ to: `/directory/${member.type}` })}
          backgroundColor={colors.text.mediumBlue}
          labelColor={colors.text.mediumBlue}
          style={{ border: `2px solid ${colors.text.mediumBlue}`, width: '100%' }}
          hoverBackgroundColor={colors.text.mediumBlue}
          labelHoverColor={colors.extra.white}
          iconAfterLabelSvgPath={svgIcons.rightArrow}
          iconAfterLabelSvgColor={colors.text.mediumBlue}
          iconAfterLabelSvgSize={14}
        />
      </HStack>
      <VStack flex={1} alignItems={'flex-start'} width={'100%'} gap={'14px'}>
        <HStack width={'100%'} justifyContent={'space-between'}>
          <VStack gap={'4px'} align={'start'}>
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              PEER’S AGE
            </Text>
            <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
              {member.yearsOfAge}
            </Text>
          </VStack>
          <VStack gap={'4px'} align={'start'}>
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              PHONE NUMBER
            </Text>
            <Text variant={'urbanistSemiBoldRegular'} color={'text.mediumBlue'}>
              {member.phone ?? '/'}
            </Text>
          </VStack>
        </HStack>
        <VStack flex={1} alignItems={'flex-start'} width={'100%'} gap={'4px'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            BIO
          </Text>
          <Text
            height={'100%'}
            width={'100%'}
            whiteSpace={'pre-wrap'}
            variant={'urbanistSemiBoldRegular'}
            color={'text.mediumBlue'}
            noOfLines={14}
          >
            {member?.bio ?? 'No Bio'}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default PeerSupportCardContent;
