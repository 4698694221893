import useOrganizationPeerSupportMembers from '@/hooks/peersupport/useOrganizationPeerSupportMembers';
import PeerSupportDirectoryCard from '@/modules/directory/cards/PeerSupportDirectoryCard';
import DirectoryPageTemplate from '@/modules/directory/DirectoryPageTemplate';
import { createFileRoute } from '@tanstack/react-router';

const PeerSupportDirectory = () => {
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useOrganizationPeerSupportMembers();

  return (
    <DirectoryPageTemplate
      title="Your Peer Support Team"
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onViewMoreClick={fetchNextPage}
    >
      {data?.map((peerSupport) => <PeerSupportDirectoryCard member={peerSupport} key={peerSupport?.id} />)}
    </DirectoryPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_peer-support/directory/peer-support')({
  component: PeerSupportDirectory,
});
