import React from 'react';
import DirectoryCardBase from './DirectoryCardBase';
import { TTherapistApi } from '@/types/Therapist.types';
import { Text, VStack } from '@chakra-ui/react';
import RequestATherapySessionModalTrigger from '@/modules/user/RequestATherapySessionModalTrigger';
import colors from '@/theme/colors';
import { displayCapitalizedUnderscoredString } from '@/utils/stringUtils';

type Props = {
  member: TTherapistApi;
};

const ACTION_BUTTON_WIDTH = '120px';

const TherapistDirectoryCard = ({ member }: Props) => {
  const name = `${member.first_name} ${member.last_name}`;

  return (
    <DirectoryCardBase
      name={name}
      pictureUrl={member.profile_photo}
      pictureLink={'/directory/therapist/$therapistId'}
      routeParams={{ therapistId: member._id }}
    >
      <VStack flex={1} alignItems={'flex-start'} gap={'14px'}>
        <VStack gap={'4px'} alignItems={'flex-start'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            ABOUT ME
          </Text>
          <Text
            height={'100%'}
            width={'100%'}
            whiteSpace={'pre-wrap'}
            variant={'urbanistSemiBoldRegular'}
            color={'text.mediumBlue'}
            noOfLines={5}
          >
            {member.bio ?? 'No About Me'}
          </Text>
        </VStack>
        <VStack gap={'4px'} alignItems={'flex-start'}>
          <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
            SPECIALTIES
          </Text>
          <Text
            height={'100%'}
            width={'100%'}
            whiteSpace={'pre-wrap'}
            variant={'urbanistSemiBoldRegular'}
            color={'text.mediumBlue'}
            noOfLines={5}
          >
            {member.designation.map((d) => displayCapitalizedUnderscoredString(d)).join(', ')}
          </Text>
        </VStack>
      </VStack>
      <RequestATherapySessionModalTrigger
        triggerButtonTransparent
        triggerButtonWidth={ACTION_BUTTON_WIDTH}
        backgroundColor={colors.extra.white}
        noShadow
        hoveredBackgroundColor="white"
        hoveredLabelColor="primary.500"
        therapist={{
          name: name,
          id: member._id,
          designation: member.designation,
          profilePhoto: member.profile_photo,
        }}
      />
    </DirectoryCardBase>
  );
};

export default TherapistDirectoryCard;
