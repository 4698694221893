import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import {
  ANSWER_VALUE_TO_TRIGGER_SUBDOMAINS,
  READINESS_DOMAIN,
  SCREENER_SUB_DOMAINS_DISPLAY_LABELS,
  WELLNESS_DOMAINS_LIST,
} from '@/constants';
import ControlledSliderExtended from '@/providers/DocumentUiProvider/components/SliderExtended/ControlledSliderExtended';
import { useScreenerStore } from '@/store/useScreenerStore';
import colors from '@/theme/colors';
import { THealthDomain, THealthSubDomain } from '@/types/Health.types';
import { Button, HStack, Image, Progress, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import useEmblaCarousel from 'embla-carousel-react';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CurrentSubdomainsSlidesContainer from './CurrentSubdomainsSlidesContainer';
import { DOMAIN_ICON_SIZE, MAX_SCREENER_SLIDE_WIDTH } from '@/constants/dimensions';
import {
  QUESTIONS_PER_DOMAIN_AND_READINESS,
  SUBDOMAIN_SCORES_FORM_KEY,
  WELLNESS_SUBDOMAINS_PER_DOMAIN_AND_READINESS,
} from '@/constants/screenerQuestions';
import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import RepresentationalOrgWholenessReportModal from './RepresentationalOrgWholenessReportModal';
import SuicideInformationModal from './SuicideInformationModal';
import PressingConcerns from './PressingConcerns';
import { OTHER_PLEASE_SPECIFY } from '@/constants/stringVars';

const screenerPageFirstSlideTitle =
  'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.';
const screenerPageFirstSlideSubTitle =
  'Your information is 100% confidential and data from this screener will be used to understand your goals and needs. What people in command of wellness in your organization will see is anonymized. You can see sample of a organization';

const TIMEOUT_MILLISECOND_DELAY = 300;

const SCREENER_DOMAINS_LIST = [...WELLNESS_DOMAINS_LIST, READINESS_DOMAIN];

const ScreenerContainer = () => {
  const [orgWellnessReportModalVisible, setOrgWellnessReportModalVisible] = useState(false);
  const [suicideModalVisible, setSuicideModalVisible] = useState(false);

  const {
    setCurrentSlide: setCurrentSlideToStore,
    currentSlide: currentSlideFromStore,
    currentFormState: currentFormStateFromStore,
    setCurrentFormState: setCurrentFormStateToStore,
    currentDomainIndex: currentDomainIndexFromStore,
    setCurrentDomainIndex: setCurrentDomainIndexToStore,
    reset: resetFormToStore,
    timeStarted,
    setTimeStarted,
  } = useScreenerStore();

  const [currentDomainIndex, setCurrentDomainIndex] = useState(currentDomainIndexFromStore);
  const currentDomain = SCREENER_DOMAINS_LIST[currentDomainIndex];

  const [currentSlide, setCurrentSlide] = useState(currentSlideFromStore);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: 0,
    watchDrag: () => {}, // disables dragging
  });

  useEffect(() => {
    setCurrentSlideToStore(currentSlide);
    setCurrentDomainIndexToStore(currentDomainIndex);

    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), TIMEOUT_MILLISECOND_DELAY);
  }, [currentSlide, currentDomainIndex]);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getValues, setValue, formState, reset: resetCurrentForm, watch } = methods;

  const { dirtyFields } = formState;

  const currentDomainAnswer: { value: number; text?: string } = watch(`${currentDomain}`);

  const selectedDomainSubdomainsFormKey = `${SUBDOMAIN_SCORES_FORM_KEY}`;

  const selectedDomainSubdomainCheckboxes: Record<string, Record<THealthSubDomain, any[]>> = watch(
    selectedDomainSubdomainsFormKey,
  );

  const selectedSubdomainsOfCurrentDomain = selectedDomainSubdomainCheckboxes?.[currentDomain]
    ? Object.keys(selectedDomainSubdomainCheckboxes[currentDomain])
    : [];

  const totalSubmittedSubdomainsPerDomain = selectedSubdomainsOfCurrentDomain.length;

  useEffect(() => {
    setTotalSlides((prev) => {
      const newSlideLength = emblaApi?.slideNodes().length;
      return newSlideLength ?? prev;
    });
  }, [totalSubmittedSubdomainsPerDomain]);

  const currentAnswerValueTriggersSubdomains = useMemo(
    () => (currentDomainAnswer ? currentDomainAnswer.value <= ANSWER_VALUE_TO_TRIGGER_SUBDOMAINS : false),
    [currentDomainAnswer],
  );

  // remove any checkbox selection if user comes back and answers more than ANSWER_VALUE_TO_TRIGGER_SUBDOMAINS
  useEffect(() => {
    if (!currentAnswerValueTriggersSubdomains) {
      setValue(`${selectedDomainSubdomainsFormKey}.${currentDomain}`, {});
    }
  }, [currentAnswerValueTriggersSubdomains, selectedDomainSubdomainsFormKey, currentDomain]);

  const updateDomainSubdomainCheckboxes = useCallback(
    (subdomain: THealthSubDomain) => {
      let newCheckboxesState = { ...selectedDomainSubdomainCheckboxes?.[currentDomain] };

      const selectedCheckboxesList = selectedDomainSubdomainCheckboxes?.[currentDomain]
        ? Object.keys(selectedDomainSubdomainCheckboxes?.[currentDomain])
        : [];

      if (selectedCheckboxesList.includes(subdomain)) {
        const newCheckboxesList = selectedCheckboxesList.filter((selectedSubdomain) => selectedSubdomain !== subdomain);
        newCheckboxesState = Object.fromEntries(
          newCheckboxesList.map((newCheckbox) => [
            [newCheckbox],
            selectedDomainSubdomainCheckboxes[currentDomain][newCheckbox],
          ]),
        );
      } else {
        if (subdomain.toLowerCase().includes('suicide')) {
          setSuicideModalVisible(true);
        }
        newCheckboxesState = { ...selectedDomainSubdomainCheckboxes?.[currentDomain], [subdomain]: [] };
      }

      setValue(`${selectedDomainSubdomainsFormKey}.${currentDomain}`, newCheckboxesState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [selectedDomainSubdomainsFormKey, selectedDomainSubdomainCheckboxes, currentDomain],
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // displays a modal if a screener was started earlier and not finished
  useEffect(() => {
    Object.entries(currentFormStateFromStore).forEach((entry) => {
      setValue(entry[0], entry[1], { shouldDirty: true, shouldTouch: true });
    });

    setCurrentDomainIndex(currentDomainIndexFromStore);

    if (timeStarted) {
      setShowConfirmationModal(true);
    } else {
      setTimeStarted(new Date());
    }
  }, []);

  const [totalSlides, setTotalSlides] = useState(0);

  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === totalSlides - 1;

  const goBackVisible = useMemo(() => currentSlide > 0, [currentSlide]);

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => prev - 1);
    emblaApi?.scrollPrev();
  };

  // initial scroll slide fix
  useEffect(() => {
    if (emblaApi) {
      emblaApi?.scrollTo(currentSlideFromStore);

      if (totalSlides === 0) {
        setTotalSlides(emblaApi.slideNodes().length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  const restartScreener = useCallback(() => {
    resetCurrentForm();
    setCurrentSlide(0);
    setCurrentDomainIndex(0);

    resetFormToStore();

    setTimeStarted(new Date());

    setShowConfirmationModal(false);
  }, []);

  const showNextSlide = useCallback(() => {
    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      setCurrentSlide((prev) => prev + 1);
      emblaApi?.scrollNext();

      setCurrentFormStateToStore(getValues());

      window.scrollTo({ top: 0, behavior: 'smooth' });

      return;
    }
  }, [emblaApi]);

  const progress = totalSlides == 0 ? 0 : ((currentSlide + 1) / totalSlides) * 100; // ternary expression removes initial 100% progress flicker

  const onContinueDomainWithoutSubdomainsOrLastSubdomainSlide = () => {
    showNextSlide();

    setCurrentDomainIndex((currentDomainIndex) => {
      // do not increase domain index if we are at the last domain (i.e. Readiness)
      if (currentDomainIndex === QUESTIONS_PER_DOMAIN_AND_READINESS.length - 1) {
        return currentDomainIndex;
      }

      return currentDomainIndex + 1;
    });
  };

  const onContinueDomain = useCallback(() => {
    const anySubdomainIsOther = selectedSubdomainsOfCurrentDomain?.find((subdomain) =>
      subdomain.toLowerCase().startsWith('other'),
    );

    const hasSubdomainsWithQuestions = anySubdomainIsOther
      ? selectedSubdomainsOfCurrentDomain?.length > 1
      : selectedSubdomainsOfCurrentDomain?.length > 0;

    if (hasSubdomainsWithQuestions) {
      showNextSlide();
    } else {
      onContinueDomainWithoutSubdomainsOrLastSubdomainSlide();
    }
  }, [selectedSubdomainsOfCurrentDomain, showNextSlide, onContinueDomainWithoutSubdomainsOrLastSubdomainSlide]);

  const onGoBackDomain = useCallback(() => {
    goToPreviousSlide();

    setCurrentDomainIndex((prev) => prev - 1);
  }, [goToPreviousSlide, currentDomainIndex]);

  const continueDisabled = useMemo(() => {
    const isCurrentQuestionAnswered = dirtyFields[currentDomain];
    const selectedCheckboxes = selectedDomainSubdomainCheckboxes?.[currentDomain];

    const otherSubdomain = WELLNESS_SUBDOMAINS_PER_DOMAIN_AND_READINESS[currentDomain as THealthDomain].find(
      (subdomain) => subdomain.toLowerCase().startsWith('other'),
    );
    const otherSubdomainUnselectedOrSelectedAndValid =
      otherSubdomain && !!selectedCheckboxes && !!selectedCheckboxes[otherSubdomain]
        ? !!selectedCheckboxes[otherSubdomain]?.[0] && !!selectedCheckboxes[otherSubdomain][0].text
        : true;

    const subdomainsInvalid =
      (currentAnswerValueTriggersSubdomains && !selectedCheckboxes) ||
      (currentAnswerValueTriggersSubdomains && selectedCheckboxes && Object.keys(selectedCheckboxes).length === 0) ||
      (currentAnswerValueTriggersSubdomains && selectedCheckboxes && !otherSubdomainUnselectedOrSelectedAndValid);

    return !isCurrentQuestionAnswered || !!subdomainsInvalid;
  }, [
    currentDomain,
    Object.keys(dirtyFields).length,
    currentAnswerValueTriggersSubdomains,
    selectedDomainSubdomainCheckboxes,
    selectedSubdomainsOfCurrentDomain,
  ]);

  const mainScreenerSlides = useMemo(() => {
    const domainSlides = QUESTIONS_PER_DOMAIN_AND_READINESS.map((domainQuestion) => {
      const currentQuestionSelectedSubdomains = Object.keys(
        selectedDomainSubdomainCheckboxes?.[domainQuestion.domain] ?? {},
      );

      const selectedSubdomainsWithoutOther = currentQuestionSelectedSubdomains.filter(
        (subdomain) => !subdomain.toLowerCase().startsWith('other'),
      );

      const subdomainSlidesShouldBeVisible = selectedSubdomainsWithoutOther.length > 0;

      return (
        <Fragment key={`domain-container-${domainQuestion.domain}`}>
          {/* Domain slide */}
          <VStack className="embla__slide" key={domainQuestion.domain}>
            <VStack paddingBottom={'40px'} width={'100%'}>
              {/* when SUBDOMAIN slides are visible, we hide the DOMAIN image and name */}
              <VStack maxWidth={MAX_SCREENER_SLIDE_WIDTH}>
                <VStack
                  width={'100px'}
                  height={'100px'}
                  bg={'white'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'20px'}
                  boxShadow={'0px 8px 40px 0px #4E1AEF33'}
                  marginY={'40px'}
                >
                  <Image {...domainQuestion.imageProps} height={DOMAIN_ICON_SIZE} width={DOMAIN_ICON_SIZE} />
                </VStack>
                <Text variant="loraSectionTitle">{domainQuestion.title}</Text>

                <Text variant={'assessmentQuestionTitle'}>{domainQuestion.questionDescription}</Text>
                <Text variant={'assessmentQuestionText'} marginTop="20px" marginBottom="40px">
                  {domainQuestion.scoreDescription}
                </Text>

                <ControlledSliderExtended
                  name={domainQuestion.domain}
                  healthDomain={domainQuestion.domain}
                  question={domainQuestion.questionDescription}
                  labelStart={'1 - Poor'}
                  labelEnd={'Excellent - 10'}
                />

                {/* Subdomain Checkboxes */}
                {currentAnswerValueTriggersSubdomains && domainQuestion.domain === currentDomain && (
                  <VStack>
                    <Text variant={'assessmentQuestionTitle'} marginTop={'100px'}>
                      Which of these concerns you?
                    </Text>
                    <Text variant={'assessmentQuestionText'}>Check all that apply.</Text>

                    <VStack gap={'20px'} marginTop={'20px'}>
                      {domainQuestion.subdomains.map((subdomain: any) => {
                        const isOther = subdomain.toLowerCase().startsWith('other');

                        const isChecked = currentQuestionSelectedSubdomains.includes(subdomain);

                        const otherFieldKey = `${selectedDomainSubdomainsFormKey}.${domainQuestion.domain}.${subdomain}.[0].text`;
                        const textInputValue = getValues(otherFieldKey) ?? '';

                        return (
                          <CheckBoxTile<{ label: string }>
                            key={SCREENER_SUB_DOMAINS_DISPLAY_LABELS[subdomain]}
                            checkboxOption={{
                              label: isOther ? OTHER_PLEASE_SPECIFY : SCREENER_SUB_DOMAINS_DISPLAY_LABELS[subdomain],
                            }}
                            isChecked={isChecked}
                            onClick={() => updateDomainSubdomainCheckboxes(subdomain)}
                            hasTextInput={isChecked && isOther}
                            textInputValue={isOther ? textInputValue : ''}
                            setTextInputValue={(newText) => setValue(otherFieldKey, newText)}
                            checkboxWidth="600px"
                            maxTextWidth="500px"
                            fontSize="18px"
                          />
                        );
                      })}
                    </VStack>
                  </VStack>
                )}
              </VStack>
            </VStack>

            {/* Continue and Go Back buttons of the Domain slide - visible on all domain slides except for the last one (Concerns) */}
            {!isLastSlide && (
              <VStack marginTop={'20px'} marginBottom={'40px'} width={'100%'}>
                <Button
                  variant={'continue'}
                  isDisabled={continueDisabled}
                  _hover={{
                    backgroundColor: continueDisabled ? 'primary.400' : 'primary.600',
                  }}
                  onClick={onContinueDomain}
                >
                  Continue
                </Button>

                <CustomButton
                  isTransparent
                  labelHoverColor="primary.black"
                  label="Go Back"
                  borderHoverColor="transparent"
                  onClick={onGoBackDomain}
                  style={{ visibility: goBackVisible ? 'visible' : 'hidden' }}
                />
              </VStack>
            )}
          </VStack>
          {/* end of Domain Slide */}

          {/* Subdomain Slide (with a separate, subdomain-slider inside) */}
          {subdomainSlidesShouldBeVisible && (
            <VStack className="embla__slide" key={`${domainQuestion.domain}-subdomains`}>
              <VStack width={'100%'}>
                {currentDomain && (
                  <CurrentSubdomainsSlidesContainer
                    domain={currentDomain}
                    subdomains={selectedSubdomainsWithoutOther}
                    onFirstSlideGoBack={goToPreviousSlide}
                    onLastSlideContinue={(submittedSubdomainAnswers) => {
                      const currentSubdomainAnswers = getValues(`${[SUBDOMAIN_SCORES_FORM_KEY]}.${currentDomain}`);

                      setValue(`${[SUBDOMAIN_SCORES_FORM_KEY]}.${currentDomain}`, {
                        ...currentSubdomainAnswers,
                        ...submittedSubdomainAnswers,
                      });

                      onContinueDomainWithoutSubdomainsOrLastSubdomainSlide();
                    }}
                  />
                )}
              </VStack>
            </VStack>
          )}
        </Fragment>
      );
    });

    return domainSlides;
  }, [
    QUESTIONS_PER_DOMAIN_AND_READINESS,
    selectedDomainSubdomainCheckboxes,
    selectedSubdomainsOfCurrentDomain,
    currentAnswerValueTriggersSubdomains,
    currentDomain,
    continueDisabled,
    isLastSlide,
    goBackVisible,
  ]);

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100vw' }}>
        {/* Progress with page title and subTitle */}
        <VStack>
          <VStack maxWidth={{ md: '820px' }} width={'100%'}>
            <Progress
              colorScheme="primary"
              height={'6px'}
              value={progress}
              width={'100%'}
              borderRadius={'20px'}
              backgroundColor={'rgba(78, 26, 239, 0.075)'}
              marginBottom={'40px'}
            />
            {isFirstSlide && (
              <>
                <Text
                  variant={'lora'}
                  textAlign={'center'}
                  fontSize={{ md: '26px' }}
                  fontStyle={'italic'}
                  lineHeight={'32px'}
                  color={'text.darkBlue'}
                  marginTop={'34px'}
                >
                  {screenerPageFirstSlideTitle}
                </Text>
                <HStack display={'inline'}>
                  <Text
                    textAlign={'center'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                    color="text.mediumGray"
                    marginTop={'24px'}
                    display={'inline'}
                  >
                    {screenerPageFirstSlideSubTitle}{' '}
                  </Text>
                  <Text
                    variant={'urbanistBold'}
                    color="text.mediumGray"
                    display={'inline'}
                    cursor={'pointer'}
                    transition={'color 0.2s'}
                    _hover={{ color: 'text.mediumBlue' }}
                    onClick={() => setOrgWellnessReportModalVisible(true)}
                  >
                    wellness report here <span style={{ fontSize: '12px' }}>➔</span>
                  </Text>
                </HStack>
                <HStack
                  height={'1px'}
                  maxWidth={'414px'}
                  width={'100%'}
                  bg="background.blueGray"
                  marginY={'30px'}
                  borderRadius={'full'}
                  opacity={0.3}
                />
                <Text variant={'loraMedium'} color="text.mediumBlue" fontWeight={400}>
                  Tell us how you feel each of these parts of your life are currently.
                </Text>
              </>
            )}
          </VStack>
        </VStack>

        <section className="embla" key={timeStarted?.toString()}>
          {/* using timeStarted as key allows us to trigger re-render of slides upon starting a new screener session */}
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {mainScreenerSlides}
              <VStack className="embla__slide" key={'pressing-concerns'}>
                <VStack paddingBottom={'40px'} width={'100%'}>
                  <PressingConcerns onGoBack={goToPreviousSlide} />
                </VStack>
              </VStack>
            </div>
          </div>
        </section>
      </form>

      {/* Continue Confirmation Modal */}
      <ModalContainer
        isOpen={showConfirmationModal}
        closeOnOverlayClick={false}
        onClose={() => setShowConfirmationModal(false)}
        isBlur={false}
        width={'520px'}
        height={'270px'}
        backgroundColor={'background.lightGray'}
        footerContent={
          <HStack justifyContent={'center'} width={'100%'} height={'100%'} spacing={'10px'} marginBottom={'10px'}>
            <CustomButton
              isTransparent
              label="Start Over"
              labelStyle={{ fontSize: '16px' }}
              labelColor="text.mediumBlue"
              labelHoverColor={colors.extra.black}
              borderHoverColor="transparent"
              onClick={restartScreener}
              height={'60px'}
              width={'120px'}
            />
            <CustomButton
              label="Continue"
              labelStyle={{ fontSize: '16px' }}
              labelHoverColor={colors.extra.white}
              backgroundColor="secondary.500"
              labelColor="extra.white"
              onClick={() => setShowConfirmationModal(false)}
              height={'60px'}
              width={'120px'}
            />
          </HStack>
        }
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
          Continue where you left off?
        </Text>
        <VStack marginTop={'10px'} justifyContent={'center'}>
          <Text variant={'urbanistMedium'} color={'text.mediumBlue'} fontSize={'16px'}>
            You started this screener on {timeStarted ? format(timeStarted, `MM/dd/yyyy hh:mm a`) : ''}.
          </Text>
          <Text variant={'urbanistMedium'} color={'text.mediumBlue'} fontSize={'16px'} marginTop={'10px'}>
            Would you like to continue or start over?
          </Text>
        </VStack>
      </ModalContainer>

      <RepresentationalOrgWholenessReportModal
        isVisible={orgWellnessReportModalVisible}
        setIsVisible={setOrgWellnessReportModalVisible}
      />

      <SuicideInformationModal isVisible={suicideModalVisible} setIsVisible={setSuicideModalVisible} />
    </FormProvider>
  );
};

export default ScreenerContainer;
