import iconsPng from '@/assets/img/png/icons';
import svgIcons from '@/assets/svg/icons';
import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import RadioButtonGroup from '@/components/molecules/RadioButtonGroup';
import { DOMAIN_ICON_SIZE, MAX_SCREENER_SLIDE_WIDTH } from '@/constants/dimensions';
import useAssessmentResult from '@/hooks/assessments/useAssessmentResult';
import { useScreenerStore } from '@/store/useScreenerStore';
import colors from '@/theme/colors';
import { Button, Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SuicideInformationModal from './SuicideInformationModal';

type TYesOrNo = 'Yes' | 'No';

const myOmniaMemberCarePhoneElement = (
  <a href={'tel:+1-385-298-8152'} style={{ fontWeight: 700 }}>
    385-298-8152
  </a>
);

const myOmniaEmailElement = (
  <a
    href={'mailto:membercare@myomnia.health?subject=Request for Support'}
    target="_blank"
    style={{ textDecoration: 'underline', fontWeight: 700 }}
  >
    membercare@myomnia.health
  </a>
);

const SELF_INJURING_SUICIDE = 'Self-injurious behaviors (e.g., cutting, suicide)';

const concernToDescriptionElementMapping: Record<string, JSX.Element> = {
  'Persistent feelings of hopelessness or sadness': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Your feelings matter, and support is available. Contact MyOmnia's Member Care team Monday through Friday, 8 AM
        to 5 PM Mountain Time, at {myOmniaMemberCarePhoneElement} or email {myOmniaEmailElement} for compassionate
        guidance.
      </Text>
    </VStack>
  ),
  // 'When life feels too heavy, reach out to MyOmnia’s Member Care team at 385-298-8152 or membercare@myomnia.health.',
  'Feeling overwhelmed or unable to cope': (
    <VStack gap={'20px'}>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        When life feels too heavy, reach out to MyOmnia’s Member Care team at {myOmniaMemberCarePhoneElement} or email{' '}
        {myOmniaEmailElement}.
      </Text>
      <Text variant={'urbanistMediumRegular'}>
        For after-hours crisis support, contact emergency services or the{' '}
        <a href={'tel:988'} style={{ fontWeight: 700 }}>
          988
        </a>{' '}
        Suicide & Crisis Lifeline.
      </Text>
    </VStack>
  ),
  'Misuse of alcohol or other drugs': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Finding healthier coping mechanisms is possible. Contact MyOmnia’s Member Care team at{' '}
        {myOmniaMemberCarePhoneElement} or {myOmniaEmailElement} for confidential support.
      </Text>
    </VStack>
  ),
  'Experiencing or witnessing domestic violence': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Your safety is our priority. Contact MyOmnia's Member Care team at {myOmniaMemberCarePhoneElement} or{' '}
        {myOmniaEmailElement}.
      </Text>

      <Text variant={'urbanistMediumRegular'}>
        For immediate crisis assistance outside business hours, call the National Domestic Violence Hotline{' '}
        <a href={'tel:+1-800-799-7233'} style={{ fontWeight: 700 }}>
          (1-800-799-7233)
        </a>{' '}
        Suicide & Crisis Lifeline.
      </Text>
    </VStack>
  ),
  'Recent or unresolved grief/loss': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Processing loss takes patience. Connect with MyOmnia's Member Care team for grief counseling or support groups
        at {myOmniaMemberCarePhoneElement} or {myOmniaEmailElement}.
      </Text>
    </VStack>
  ),
  'Flashbacks or intrusive thoughts related to past trauma': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Healing is possible. Reach out to MyOmnia's Member Care team for trauma-informed resources at{' '}
        {myOmniaMemberCarePhoneElement} or {myOmniaEmailElement}.
      </Text>
    </VStack>
  ),
  [SELF_INJURING_SUICIDE]: (
    <VStack>
      <Text variant={'urbanistMediumRegular'}>
        Your life has value. Contact MyOmnia’s Member Care team at {myOmniaMemberCarePhoneElement} or{' '}
        {myOmniaEmailElement}. For immediate crisis help, call{' '}
        <a href={'tel:988'} style={{ fontWeight: 700 }}>
          988
        </a>{' '}
        or emergency services at{' '}
        <a href={'tel:911'} style={{ fontWeight: 700 }}>
          911
        </a>
        .
      </Text>
    </VStack>
  ),
  'Relationship difficulties (e.g., conflict with partner or family)': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        You are not alone. Contact MyOmnia’s Member Care team for relationship support at{' '}
        {myOmniaMemberCarePhoneElement} or {myOmniaEmailElement}.
      </Text>
    </VStack>
  ),
  'Other (please specify)': (
    <VStack>
      <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'} whiteSpace={'pre-wrap'}>
        Whatever you're facing, support is available. Contact MyOmnia’s Member Care team at{' '}
        {myOmniaMemberCarePhoneElement} or {myOmniaEmailElement}.
      </Text>
    </VStack>
  ),
};

const concernsChecklist = [
  'Persistent feelings of hopelessness or sadness',
  'Feeling overwhelmed or unable to cope',
  'Misuse of alcohol or other drugs',
  'Experiencing or witnessing domestic violence',
  'Recent or unresolved grief/loss',
  'Flashbacks or intrusive thoughts related to past trauma',
  SELF_INJURING_SUICIDE,
  'Relationship difficulties (e.g., conflict with partner or family)',
  'Other (please specify)',
];

interface Props {
  onGoBack: () => void;
}

const PressingConcerns = ({ onGoBack }: Props) => {
  const { reset: resetScreenerStore, currentFormState: currentFormStateFromStore } = useScreenerStore();

  const [hasPressingConcerns, setHasPressingConcerns] = useState<'Yes' | 'No'>();

  const [suicideModalVisible, setSuicideModalVisible] = useState(false);

  const [checkedConcerns, setCheckedConcerns] = useState<string[]>([]);

  const [criticalOtherText, setCriticalOtherText] = useState('');

  // Every time user clicks 'No', we remove all previously checked concerns
  useEffect(() => {
    if (hasPressingConcerns === 'No') setCheckedConcerns([]);
  }, [hasPressingConcerns]);

  const updateCheckedConcerns = useCallback(
    (newlyCheckedConcern: string) => {
      let newCheckedConcerns = [...checkedConcerns];

      if (newCheckedConcerns.includes(newlyCheckedConcern)) {
        newCheckedConcerns = newCheckedConcerns.filter(
          (previouslyCheckedConcern) => previouslyCheckedConcern !== newlyCheckedConcern,
        );
      } else {
        if (newlyCheckedConcern === SELF_INJURING_SUICIDE) {
          setSuicideModalVisible(true);
        }

        newCheckedConcerns = [...newCheckedConcerns, newlyCheckedConcern];
      }

      setCheckedConcerns(newCheckedConcerns);
    },
    [checkedConcerns],
  );

  const continueDisabled = useMemo(
    () => !hasPressingConcerns || (hasPressingConcerns === 'Yes' && checkedConcerns.length === 0),
    [hasPressingConcerns, checkedConcerns],
  );

  const { isPending, mutate, isSuccess } = useAssessmentResult(resetScreenerStore);

  const onFinish = () => {
    const questions = concernsChecklist.map((concern, i) => {
      const value = !!checkedConcerns.find((checkedConcern) => concern === checkedConcern);
      if (concern.toLowerCase().startsWith('other')) {
        return { id: i + 1, value, question: concern, text: criticalOtherText };
      }

      return { id: i + 1, value, question: concern };
    });

    mutate({ formValues: { ...currentFormStateFromStore, critical: { questions } } });
  };

  if (isSuccess) {
    return (
      <Center height={'100%'}>
        <Text variant={'loraTitle'} marginTop={'40px'}>
          Screener completed! Redirecting...
        </Text>
      </Center>
    );
  }

  return (
    <VStack paddingBottom={'40px'} width={'100%'}>
      <VStack maxWidth={MAX_SCREENER_SLIDE_WIDTH}>
        <VStack
          width={'100px'}
          height={'100px'}
          bg={'white'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'20px'}
          boxShadow={'0px 8px 40px 0px #4E1AEF33'}
          marginY={'40px'}
        >
          <Image src={iconsPng.whiteExclamationOnPurpleTriangle} height={DOMAIN_ICON_SIZE} width={DOMAIN_ICON_SIZE} />
        </VStack>
        <Text variant="loraSectionTitle">Pressing Concerns</Text>

        <Text variant={'assessmentQuestionTitle'}>
          Do you have any pressing concerns you would like to discuss immediately with a Member Care Coordinator?
        </Text>
        <Text variant={'assessmentQuestionText'} marginTop="20px" marginBottom="40px">
          Consider current challenges like mental and physical health; parenting and relationships; substance use and
          addiction; trauma and safety; and life challenges and stressors.
        </Text>

        <RadioButtonGroup
          items={['Yes', 'No']}
          selectedItem={hasPressingConcerns ?? ''}
          onClick={(yesOrNo: string) => setHasPressingConcerns(yesOrNo as TYesOrNo)}
          borderWidth={3}
        />
        {/* Subdomain Checkboxes */}
        {hasPressingConcerns === 'Yes' && (
          <VStack>
            <Text variant={'assessmentQuestionTitle'} marginTop={'80px'} marginBottom={'50px'} width={'600px'}>
              Please check all the statements that describe immediate concerns in your life:
            </Text>
            <VStack gap={'20px'} marginTop={'20px'}>
              {concernsChecklist.map((concern) => {
                const isChecked = checkedConcerns.includes(concern);

                const isOther = concern.toLowerCase().startsWith('other');

                return (
                  <VStack key={concern}>
                    <CheckBoxTile<{ label: string }>
                      key={concern}
                      isChecked={isChecked}
                      checkboxOption={{ label: concern }}
                      hasTextInput={isChecked && isOther}
                      textInputValue={criticalOtherText}
                      setTextInputValue={(newText: string) => setCriticalOtherText(newText)}
                      onClick={() => updateCheckedConcerns(concern)}
                      checkboxWidth="600px"
                      maxTextWidth="500px"
                      fontSize="18px"
                      borderWidth={3}
                    />
                    {isChecked && (
                      <HStack width={'600px'} paddingX={'20px'} paddingY={'10px'} gap={'22px'}>
                        <VStack>
                          <SvgIcon iconPath={svgIcons.infoCircle} color={colors.secondary[500]} size={20} />
                        </VStack>
                        <VStack>{concernToDescriptionElementMapping[concern]}</VStack>
                      </HStack>
                    )}
                  </VStack>
                );
              })}
            </VStack>
          </VStack>
        )}
      </VStack>

      {/* Continue and Go Back buttons of the Domain slide - visible on all domain slides except for the last one (Concerns) */}
      <VStack marginTop={'60px'} width={'100%'}>
        <Button
          variant={'continue'}
          isDisabled={continueDisabled}
          _hover={{
            backgroundColor: continueDisabled ? 'primary.400' : 'primary.600',
          }}
          onClick={onFinish}
          isLoading={isPending}
        >
          Finish
        </Button>

        <CustomButton
          isTransparent
          labelHoverColor="extra.black"
          label="Go Back"
          borderHoverColor="transparent"
          onClick={onGoBack}
        />
      </VStack>

      <SuicideInformationModal isVisible={suicideModalVisible} setIsVisible={setSuicideModalVisible} />
    </VStack>
  );
};

export default PressingConcerns;
