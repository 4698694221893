import { useRef, type CSSProperties } from 'react';

import { HStack, Text, Checkbox, VStack, Input } from '@chakra-ui/react';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';

type TMandatoryCheckboxField = {
  label: string;
};

interface Props<T> {
  checkboxOption: T;
  isChecked: boolean;
  onClick: (_: T) => void;
  isDisabled?: boolean;
  buttonStyle?: CSSProperties;
  noOfLines?: number;
  hasTextInput?: boolean;
  textInputValue?: string;
  setTextInputValue?: (_: string) => void;
  checkboxWidth?: string;
  maxTextWidth?: string;
  fontSize?: string;
  borderWidth?: number;
}

const CheckBoxTile = <T extends TMandatoryCheckboxField>({
  checkboxOption,
  isChecked,
  onClick,
  isDisabled,
  noOfLines,
  hasTextInput,
  textInputValue,
  setTextInputValue,
  checkboxWidth = FORM_FIELD_WIDTH,
  maxTextWidth = '240px',
  fontSize = '14px',
  borderWidth = 2,
}: Props<T>) => {
  const textInputRef = useRef<HTMLInputElement>(null);

  return (
    <HStack
      background={'extra.white'}
      boxShadow={isChecked ? '' : `0px 2px 8px 0px #00417926`}
      borderRadius={8}
      padding={'16px'}
      paddingY={hasTextInput ? '10px' : '16px'}
      width={checkboxWidth}
      onClick={(e) => {
        if (isDisabled) return;
        e.preventDefault(); // necessary to prevent double-event from clicking the checkbox
        onClick(checkboxOption);

        if (!isChecked) {
          setTimeout(() => textInputRef.current?.focus(), 300);
        }
      }}
      borderWidth={borderWidth}
      borderColor={isChecked ? 'primary.500' : 'transparent'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ boxShadow: isChecked || isDisabled ? '' : `0px 2px 16px 0px #00417926` }}
      gap={0}
    >
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        colorScheme="primary"
        backgroundColor={'background.darkGray'}
        borderColor={'background.darkGray'}
        borderRadius={'4px'}
        size={'lg'}
        justifyContent={'center'}
        alignItems={'center'}
      />
      {hasTextInput ? (
        <VStack gap={0} width={'100%'}>
          <Text
            fontWeight={800}
            noOfLines={noOfLines}
            maxWidth={maxTextWidth}
            textOverflow={'ellipsis'}
            fontSize={'10px'}
            lineHeight={'10px'}
            color={isChecked ? 'text.mediumGray' : 'text.darkBlue'}
            width={'100%'}
            textAlign={'start'}
          >
            {checkboxOption.label.toUpperCase()}
          </Text>
          <VStack width={'100%'} marginLeft={'40px'} align={'start'}>
            <Input
              ref={textInputRef}
              value={textInputValue}
              onChange={(e) => setTextInputValue?.(e.target.value)}
              borderWidth={0}
              padding={'1px'}
              maxHeight={'28px'}
              focusBorderColor={'transparent'}
              placeholder={'Text'}
              color={'primary.500'}
              fontWeight={600}
              width={'90%'}
              _placeholder={{ fontWeight: '600', color: 'primary.500' }}
              onClickCapture={(e) => e.stopPropagation()}
            />
          </VStack>
        </VStack>
      ) : (
        <Text
          fontWeight={600}
          noOfLines={noOfLines}
          maxWidth={maxTextWidth}
          textOverflow={'ellipsis'}
          fontSize={fontSize}
          color={isChecked ? 'primary.500' : 'text.darkBlue'}
          marginLeft={'20px'}
          textAlign={'start'}
          width={'100%'}
        >
          {checkboxOption.label}
        </Text>
      )}
    </HStack>
  );
};

export default CheckBoxTile;
