import CustomButton from '@/components/atoms/CustomButton';
import SvgIconCalendar from '@/components/atoms/SvgIconCalendar';
import useMemberCareById from '@/hooks/membercare/useMemberCareFromMyOrgById';
import DirectoryProfileViewPageTemplate from '@/modules/directory/DirectoryProfileViewPageTemplate';
import SendEmailModalWithTrigger from '@/modules/user/SendEmailModalWithTrigger';
import colors from '@/theme/colors';
import { getAgeFromBirthDay, getMemberCareRoleFromName } from '@/utils/stringUtils';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';

const MemberCareProfileView = () => {
  const { memberCareId } = Route.useParams();

  const { data: memberCare, isLoading, isSuccess } = useMemberCareById(memberCareId);

  const [description, setDescription] = useState<string | undefined>(undefined);

  const name = `${memberCare?.firstName} ${memberCare?.lastName}`;

  const calendlyRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (memberCare?.user_metadata.dateOfBirth) {
      const userAge = getAgeFromBirthDay(memberCare?.user_metadata.dateOfBirth);
      const desc = `${userAge} years old ${memberCare?.sex.toLowerCase()}`;
      setDescription(desc);
    }
  }, [memberCare, isSuccess]);

  return (
    <DirectoryProfileViewPageTemplate
      isLoading={isLoading}
      name={`${memberCare?.firstName} ${memberCare?.lastName}`}
      profileImage={memberCare?.user_metadata.profilePicture ?? memberCare?.user_metadata.profile_photo}
      role={getMemberCareRoleFromName(name)}
      description={description}
    >
      {memberCare && (
        <HStack gap={8} marginTop={'40px'}>
          <SendEmailModalWithTrigger
            peerSupportOrMemberCare={'member-care'}
            recipientId={memberCare.user_id}
            toEmailName={memberCare.user_metadata.firstName}
            triggerButtonWidth="170px"
            backgroundColor={colors.extra.white}
            labelColor={colors.text.mediumBlue}
            triggerButtonTransparent={false}
            hoveredBackgroundColor={colors.extra.white}
            hoveredLabelColor={colors.extra.black}
          />
          <a
            ref={calendlyRef}
            target="_blank"
            href={memberCare.user_metadata?.calendly_link}
            style={{ display: 'none' }}
          >
            {memberCare.user_metadata?.calendly_link}
          </a>

          <CustomButton
            label="Schedule call"
            backgroundColor="primary.500"
            labelColor="extra.white"
            onClick={() => {
              calendlyRef.current?.click();
            }}
            width={'170px'}
            disabled={!memberCare.user_metadata?.calendly_link}
            style={{ border: `2px solid ${colors.primary[500]}` }}
            labelHoverColor="white"
            iconBeforeLabelJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
            iconBeforeLabelHoveredJSX={<SvgIconCalendar color={colors.extra.white} size={18} />}
          />
        </HStack>
      )}
      {memberCare && (
        <VStack
          backgroundColor={'white'}
          padding={'40px'}
          marginTop={'40px'}
          borderRadius={'12px'}
          alignItems={'flex-start'}
          minWidth={'500px'}
          maxWidth={'690px'}
          boxShadow={'0px 0px 20px 0px #00417926'}
        >
          <Text variant={'loraSmallTitle'} textAlign={'start'}>
            About Me
          </Text>
          <Text
            variant={'urbanistMediumLarge'}
            lineHeight={'22px'}
            color={'text.mediumBlue'}
            marginTop={'10px'}
            whiteSpace={'pre-wrap'}
          >
            {memberCare.user_metadata.bio}
          </Text>
        </VStack>
      )}
    </DirectoryProfileViewPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_directory/_member-care/directory/member-care/$memberCareId')({
  component: MemberCareProfileView,
});
