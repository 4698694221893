import { BOBBI_MC_GRAW_NAME, ROB_JAMES_NAME } from '@/constants';
import { differenceInYears } from 'date-fns';

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const base64ToFile = (base64Content: string, fileName: string): File => {
  // To retrieve only the Base64 encoded string, first remove `data:*/*;base64,` from the result. https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
  const trimmedContent = base64Content.substring(base64Content.indexOf(',') + 1);
  const buff = Buffer.from(trimmedContent, 'base64');
  return new File([buff], fileName, {
    type: fileName.endsWith('png') ? 'image/png' : 'image/jpeg',
  });
};

export const getAgeFromBirthDay = (birthDay?: string) => {
  if (!birthDay) return 0;

  const splitDate = birthDay.split('-'); // 1997-18-12
  let birthDayString = birthDay;
  if (parseInt(splitDate[0]) > 1900) {
    birthDayString = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
  }

  const birthDayDate = new Date(birthDayString);
  return differenceInYears(new Date(), birthDayDate);
};

export const generateConcernLevelChangeDisplay = (concernLevel: number) => {
  return `${concernLevel.toFixed()}% since last Q`;
};

const CHARS_TO_REMOVE_FROM_DOMAIN_NAME = [' ', '&'];

export const domainNameToSvgId = (domainName: string) => {
  let finalSvgId = domainName;

  CHARS_TO_REMOVE_FROM_DOMAIN_NAME.forEach((charToRemove) => {
    finalSvgId = finalSvgId.split(charToRemove).join('');
  });

  return finalSvgId + '-svg-id';
};

export const formatKebabCaseString = (str: string) => {
  return str
      .split('-')                      // split by dash
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize each word
      .join(' ')   
}

export const getMemberCareRoleFromName = (name: string) => {
   if (name.includes(BOBBI_MC_GRAW_NAME)) return 'Member Care Specialist';
   if (name.includes(ROB_JAMES_NAME)) return 'Member Care Director';

   return 'Member Care Coordinator';
}

export const displayCapitalizedUnderscoredString = (serviceType: string) =>
  serviceType
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
    .join(' ');